@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');

// @font-face {
//   font-family: Gthin;
//   src: url(../src/font/Gellix-Thin.woff);
// }

// @font-face {
//   font-family: Gregular;
//   src: url(../src/font/Gellix-Regular.woff);
// }

// @font-face {
//   font-family: Gmedium;
//   src: url(../src/font/Gellix-Medium.woff);
// }

// @font-face {
//   font-family: Gsemibold;
//   src: url(../src/font/Gellix-SemiBold.woff);
// }

// @font-face {
//   font-family: Gbold;
//   src: url(../src/font/Gellix-Bold.woff);
// }

// @font-face {
//   font-family: Gextrabold;
//   src: url(../src/font/Gellix-ExtraBold.woff);
// }

.importheadddddd {
  color: var(--text-color);
}

.dark-theme {
  --body-bg: #000 !important;
  --navbar-bg: #000 !important;
  --text-color: #ffffff !important;
  --card-bg: #000 !important;
  --card-border: #FFF !important;
  --nav-border: !important;
  --card-bg2: #050505 !important;
  --card-bg3: #0D0D0D !important;
  --card-bg4: #222222 !important;
  --card-border2: #242424 !important;
  --card-border3: #2e2e2e !important;
  --heading-text: #ffffff !important;
  --button-bg: #242424 !important;
  --button-bg2: #1e241f !important;
  --btn-green-border: #17441c !important;
  --input-border: #282828 !important;
  --warning-card: #23221e !important;
  --warning-card-border: #44361a !important;
  --yellow-bg: #28241e !important;
  --yellow-border: #533e18 !important;
  --svg-color: #ffffff !important;
  --Setting-wallet-Passd-lable: #636363 !important;
  --Setting-wallet-Passd-input-text: #343434 !important;
  --radio-check-border: #3d3d3d !important;
  --footer-bg: #1d1d1d !important;
  --footer-text: #828284 !important;
  --dropdown-menu-hover: #252525 !important;
  --dropdown-border-main: #262626 !important;
  --radio-check-language-bg: #252525 !important;
  --congratulation-modal-text: #ababab !important;
  --create-account: #828284 !important;
  --hover-tbl: #252525 !important;
  --brdrtbl: #242424 !important;
  --modal-inner-bg: #212121 !important;
  --scroll-bar: #2B2A2A !important;
  --linecolor: #333 !important;
  --newbgclr: #0F0F0F !important;
  --newtxtclr: #363636 !important;
  --boxbgclrnew: #0A0A0A !important;
  --newradialclr: #121212 !important;
  // newtheme

}

.light-theme {
  --scroll-bar: #2B2A2A !important;
  --brdrtbl: #EBEBEB !important;
  --hover-tbl: #f9f9f9 !important;
  --create-account: #7d7d7d !important;
  --congratulation-modal-text: #7d7d7d !important;
  --radio-check-language-bg: #f4f4f4 !important;
  --dropdown-border-main: #ebebeb !important;
  --dropdown-menu-hover: #f9f9f9 !important;
  --footer-text: #7d7d7d !important;
  --footer-bg: #fcfcfc !important;
  --radio-check-border: #d9d9d9 !important;
  --Setting-wallet-Passd-input-text: #AAA !important;
  --Setting-wallet-Passd-lable: #7d7d7d !important;
  --body-bg: #FFF !important;
  --navbar-bg: #FFF !important;
  --text-color: #7d7d7d !important;
  --card-bg: #FFF !important;
  --card-bg2: #F6F6F6 !important;
  --card-bg3: #E9E9E9 !important;
  --card-bg4: #fafafa !important;
  --card-border: #000 !important;
  --card-border2: #ebebeb !important;
  --card-border3: #cecece !important;
  --heading-text: #252525 !important;
  --button-bg: #f1f1f1 !important;
  --button-bg2: #eff9f0 !important;
  --btn-green-border: #a7e5ae !important;
  --input-border: #ebebeb !important;
  --warning-card: #fcf9f2 !important;
  --warning-card-border: #fde9c2 !important;
  --yellow-bg: #fcf8ef !important;
  --yellow-border: #fde8bf !important;
  --svg-color: #252525 !important;
  --modal-inner-bg: #ffe9e924 !important;
  --linecolor: #D6D6D6 !important;
  --newbgclr: #E6E6E6 !important;
  --newtxtclr: #8A8A8A !important;
  --boxbgclrnew: #ECECEC !important;
  --newradialclr: rgba(18, 18, 18, 0.30) !important;
  // newtheme

}

.direction-for-arabic{
  direction: rtl;
}

.light-theme .white {
  // display: block !important;
}

.dark-theme .white {
  display: none !important;
}

.dark-theme .dark {
  // display: block !important;
}

.light-theme .dark {
  display: none !important;
}

html {
  scroll-behavior: smooth;
}

.margin-rght {
  margin-left: 20px;
}

body {
  font-family: 'Space Mono', monospace;
  background: var(--body-bg);
  height: 100vh;
}

iframe {
  // display: none !important;
  z-index: -9 !important;
}

.recapcha {
  margin-top: 5px;
  display: flex;
  justify-content: center;

  iframe {
    display: block !important;
    z-index: 1 !important;
  }
}

.grecaptcha-logo {
  iframe {
    display: block !important;
    z-index: 999999 !important;
  }
}

.grecaptcha-badge {
  // right: 20px!important;
  z-index: 1111111111 !important;
}

.custom-show-modal {
  iframe {
    display: block !important;
    z-index: 1 !important;
  }
}


#checkdv_1 {
  display: none !important;
  z-index: -9 !important;
}

.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}

.common-clr {
  color: var(--card-border);
}

.wallet {
  font-weight: 400;
  font-size: 16px;
}

button.btn-verifyss {
  background: var(--body-bg);
  border: 1px solid #242424 !important;
  border-radius: 0px;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--card-border);

  padding: 40px 10px;
  gap: 13px;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: var(--card-border) !important;
    box-shadow: 2px 2px 0px 0px var(--card-border);
  }
}

.common-btnone {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--body-bg);
  border: 1px solid var(--card-border) !important;
  box-shadow: 4px 4px 0px 0px var(--card-border);
  font-style: normal;
  text-transform: uppercase;
  color: var(--card-border);
  border: none;
  padding: 10px;

  &:hover {
    box-shadow: 2px 2px 0px 0px var(--card-border);
  }
}

.secondary-btnone {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--body-bg);
  border: 1px solid var(--card-border) !important;
  box-shadow: 4px 4px 0px 0px var(--card-border);
  font-style: normal;
  text-transform: uppercase;
  color: var(--card-border);
  border: none;
  padding: 10px;
  padding: 10px;

  &:hover {
    box-shadow: 2px 2px 0px 0px var(--card-border);
  }
}

.common-btntwo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--body-bg);
  border: 1px solid var(--card-border) !important;
  box-shadow: 4px 4px 0px 0px var(--card-border);
  font-style: normal;
  text-transform: uppercase;
  color: var(--card-border);
  border: none;
  padding: 10px;
  padding: 10px;

  &:hover {
    box-shadow: 2px 2px 0px 0px var(--card-border);
  }
}

.secondary-btntwo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--button-bg);
  border-radius: 4px;
  font-style: normal;
  text-transform: uppercase;
  color: var(--heading-text);
  border: none;

  &:hover {
    background: #8f1bf5;
    box-shadow: 0px 20px 30px -10px #63626226;
    color: #ffffff;
    transition: 0.7s;
  }
}

.theme-container {
  max-width: 1240px;
  width: 100%;
  padding: 0px 20px;
  margin: 0 auto;
}

.material-textfield {
  position: relative;
  width: 100%;
}

.material-textfield label {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--card-bg);
  padding: 0 7.4rem 0 1.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 120%;
  color: var(--card-border);
}

.material-textfield input {
  outline: none;
  transition: 0.1s ease-out;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 0px;
  padding: 17px 18px;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  line-height: 117%;
  width: 100%;
  box-shadow: none !important;
  color: var(--card-border);

  &::placeholder {
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 117%;
    color: var(--Setting-wallet-Passd-input-text);
  }
}

.eye {
  position: absolute;
  top: 15px;
  right: 18px;
}

.fade.address-secret-modal.modal.show {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
}

.material-textfield input:focus+label {
  top: 0;
  left: 6px;
  transform: translateY(-50%) scale(0.9);
  padding: 0 0.3rem;
}

.material-textfield input:not(:placeholder-shown)+label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
  padding: 0 0.4rem 0 0.3rem;
}

@media (max-width: 1080px) {
  .horizontalsss {
    transform: rotate(90deg);
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .padd-sm {
    padding: 0 !important;
  }
}

.pl-2 {
  padding-left: 10px;
}

@media (max-width: 992px) {
  .pl-2 {
    padding-left: 0px;
  }
}


.set-cursor {
  cursor: pointer !important;
}

.Transfer {
  color: var(--heading-text);
}

.text-error {
  font-size: 12px;
  color: rgb(177, 76, 76);
  margin-bottom: 10px;
}

input[type="checkbox"] {
  cursor: pointer;
}

.successsssss-modal {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);

  .btn-confirm {
    background: #8f1bf5;
    box-shadow: 0px 20px 30px -10px rgba(99, 98, 98, 0.15);
    border-radius: 4px;
    color: #ffffff;
  }

  .modal-content {
    background: var(--card-bg);
    border: 1px solid var(--card-border);
    border-radius: 0px;
    padding: 25px;
    box-shadow: 4px 4px 0px 0px var(--card-border);
  }

  .modal-body {
    padding: 0;

  }

  .inner-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .main-head {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      color: var(--card-border);
      margin-top: 15px;
      margin-bottom: 16px;
    }

    .inline-text {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      margin-bottom: 16px;

      h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 140%;
        color: var(--card-border);
        display: flex;
        align-items: center;

        h6 {
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 140%;
          color: #9C9C9C;
          margin-left: 7px;
        }
      }
    }

    .para {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 110%;
      color: #7D7D7D;
    }
  }

  .twice-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    button {
      padding: 15px;
      // flex: 0 0 48%;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 100%;
      text-transform: uppercase;
      color: var(--card-border);
      border-radius: 0px;
      border: none;
      width: 100%;
    }

    .btn-closee {
      background: var(--card-bg2);
      color: var(--card-border);
    }

    // .btn-trans{
    //   background: #8F1BF5;
    //   box-shadow: 0px 20px 30px -10px rgba(99, 98, 98, 0.15);
    // }
  }
}

.success-modal .modal-header {
  background: var(--body-bg);
  border: 1px solid var(--card-border);
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-radius: 0px !important;

  .modal-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    color: var(--card-border);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
}

.dark-theme .main-upper-new-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 110%;
  text-align: center;
  margin-bottom: 35px;
  color: var(--card-border);
  padding: 8px 0;
}

.light-theme .main-upper-new-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 110%;
  text-align: center;
  color: var(--card-border);
  margin-bottom: 35px;
}

// input:-webkit-autofill {
//   -webkit-box-shadow: 0 0 0px 1000px var(--card-bg) inset !important;
//   -webkit-border-radius: 8px !important;
//   -webkit-text-fill-color: var(--text-color) !important;
// }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s !important;
}

.noora {
  margin: 0 !important;
  margin-top: -30px !important;
}

.amount-style-value {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 36px !important;
  line-height: 140% !important;
  color: #FFFFFF;
}

.amount-style-value-mbl {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 140% !important;
  color: #FFFFFF;
}


.form-check-input:checked {
  background-color: #7d7d7d !important;
  border-color: #7d7d7d !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.form-check-input {
  --bs-form-check-bg: var(--navbar-bg);
  width: 18px;
  height: 18px;
  border-radius: 0px !important;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--radio-check-border);
  outline: none !important;
  border-color: #3d3d3d !important;
}

/* hide arrows
 Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}


.fortextstyle {
  color: var(--card-border);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.1px;
}


// custom modal scss here.........................



.custom-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  background: #3e3e3e4d;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);

  .inner-set {
    background: var(--card-bg);
    border: 1px solid var(--card-border);
    border-radius: 0px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h6 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      color: var(--heading-text);
      margin-bottom: 7px;
    }

    p {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 110%;
      color: var(--congratulation-modal-text);
    }
  }
}

.custom-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 100%;
  background: var(--body-bg);
  border: 1px solid var(--card-border);
  border-radius: 0px;
  padding: 20px;
  box-shadow: 4px 4px 0px 0px var(--card-border);

  .main-body {
    .btn-done {
      border: 1px solid var(--card-border);
      background: #000;
      box-shadow: 4px 4px 0px 0px var(--card-border);
      display: flex;
      padding: 15px 40px;
      justify-content: center;
      align-items: center;
      color: var(--card-border);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      text-transform: uppercase;
      width: 100%;
    }

    .twitterbutton {
      margin-top: 25px;

      button {
        border: 1px solid var(--card-border);
        background: var(--card-bg);
        display: flex;
        padding: 25px 70px;
        justify-content: center;
        align-items: center;
        color: var(--card-border);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase;
        width: 100%;
        position: relative;
      }
    }
  }
}

.custom-close-button {
  float: right;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: lightgray;
}

.custom-close-button:hover {
  background-color: darkgray;
}

.custom-show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1.0);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}


@media (max-width:1080px) {
  .custom-modal-content {
    width: 90%;
  }
}


.checkboxmain {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  margin-top: 20px;

  .clear {
    clear: both;
  }

  .checkBox {
    display: block;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255, 255, 255, 0);
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 0px 2px var(--text-color);
  }

  .checkBox div {
    width: 60px;
    height: 60px;
    background-color: var(--text-color);
    top: -52px;
    left: -52px;
    position: absolute;
    transform: rotateZ(45deg);
    z-index: 100;
  }

  .checkBox input[type=checkbox]:checked+div {
    left: -10px;
    top: -10px;
  }

  .checkBox input[type=checkbox] {
    position: absolute;
    left: 50px;
    visibility: hidden;
  }

  .transition {
    transition: 300ms ease;
  }

  .checkboxpara {
    color: var(--text-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 117%;
  }
}


.light-theme .set-color-ontheme {
  path {
    fill: #000 !important;
  }
}

.btn-explorer {
  border: 1px solid var(--card-border) !important;
  background-color: transparent;
  box-shadow: 0px 20px 30px -10px rgba(99, 98, 98, 0.15), 4px 4px 0px 0px var(--card-border) !important;
}

.welcome-text {
  text-decoration: underline !important;
}

.dark-theme .darkmode {
  display: none !important;
}

.light-theme .lightmode {
  display: none;
}

.giftmodal-scss .btn-confirm {
  height: 50px;
  font-weight: 700;
}

@media (max-width:1080px) {
  .d-noneformobile {
    display: none;
  }
}


.yellowmain {
  border: 1px solid #FFA800;
  display: flex;
  padding: 11px;
  align-items: flex-start;
  background: #000;
  margin-top: 30px;

  .yellowtext {
    color: #FFA800;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }
}

.newdesclaimermodal {
  .modal-dialog {
    max-width: 674px;
    width: 100%;

    .modal-body {
      .forscroll {
        background: var(--body-bg) !important;
        border: none !important;
        height: 331px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #2B2A2A;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #FFF;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #FFF;
        }
      }

      .termshead {
        color: var(--card-border);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 117%;
        /* 16.38px */
        text-transform: uppercase;
        margin-bottom: 20px;
      }
    }
  }
}

.bolddiii {
  color: #FFF !important;
}

.xfgdfghfg {
  position: absolute;
}


.termsmodal {
  .modal-dialog {
    max-width: 538px !important;

    .modal-content {
      .modal-body {
        margin-bottom: 0px !important;

        .textdecor {
          color: var(--card-border);
          text-decoration: underline !important;
        }

        .blackbtn {
          display: flex;
          padding: 15px 40px;
          justify-content: center;
          align-items: center;
          background: rgba(255, 255, 255, 0.05);
          color: #363636;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%;
          width: 100%;
          border: none !important;
          text-transform: uppercase;
        }
      }
    }
  }
}

.blackbtn {
  display: flex;
  padding: 15px 40px;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  color: #363636;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  width: 100%;
  border: none !important;
  text-transform: uppercase;
}

.lowewrbtn {
  padding: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  line-height: 18px;
  color: var(--card-border);
  margin: 20px 0px;
}

.blackbtn {
  display: flex;
  padding: 15px 40px !important;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.05) !important;
  color: #363636 !important;
  font-size: 18px !important;
  line-height: 18px !important;
  width: 100%;
  border: none !important;
  text-transform: uppercase;
}

.attentionpara {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  width: 100%;
  color: var(--card-border);
  margin-bottom: 20px;
}

.twitterbuttonmain {
  position: relative;

  .asassddsadfs {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--card-border);
    font-size: 16px;
    font-style: normal;
    white-space: nowrap;
    font-weight: 700;
    line-height: 100%;
    /* 16px */
    text-transform: uppercase;
    pointer-events: none;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  button {
    span {
      display: none !important;
    }
  }
}

.twitterbuttonmain {
  margin-top: 25px;
  width: 290px;


  button {
    border: 1px solid var(--card-border);
    background: var(--card-bg);
    display: flex;
    padding: 25px 70px;
    justify-content: center;
    align-items: center;
    color: var(--card-border);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    width: 100%;
    position: relative;
    background: var(--card-bg);
    border: 1px solid var(--card-border);
    box-shadow: 4px 4px 0px 0px var(--card-border);
  }
}

.forzindex {
  z-index: 999;
}

.greenbtn {
  border: 1px solid #00B812;
  background: rgba(0, 184, 18, 0.10);
  color: #00B812;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
  display: flex;
  width: 248px;
  padding: 15px 0px;
  gap: 10px;
  justify-content: center;
  align-items: center;

  .greenimg {
    path {
      fill: #00B812;
    }
  }
}

.followbtns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.formargins {
  margin-left: 10px;
}

.confirmedmodal {
  .modal-dialog {
    max-width: 570px;

    .confirmtophead {
      color: var(--card-border);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 31px;
    }

    .greenishbox {
      background: rgba(40, 230, 100, 0.10);
      padding: 10px;


      .greenishpara {
        color: #28E664;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
      }
    }

    .donebtn {
      background: var(--card-bg);
      border: 1px solid var(--card-border);
      border-radius: 0px;
      box-shadow: 4px 4px 0px 0px var(--card-border);
      color: var(--card-border);
      display: flex;
      padding: 15px 40px;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      text-transform: uppercase;
      width: 100%;
    }
  }
}



.redishpara {
  color: #FF0D0D !important;
}

.btnwidthset {
  width: 188px !important;
}


@media(max-width:991px) {
  .followbtns {
    flex-direction: column;

    .lineimg {
      display: none !important;
    }

    .twitterbuttonmain {
      width: 100%;
    }

    .greenbtn {
      width: 100%;
    }
  }
}

.green {
  color: #00B812 !important;
}

.borderdiv {
  border: 1px solid var(--card-border);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.direction-for-arabic{
  .material-textfield label{
    left: unset;
    right: 0;
    padding: 0 1.3rem 0 7.4rem;
    margin: 0 0.5rem;
    transform-origin: right top;
    width: fit-content;

  }
  .backarrow{
    transform: rotate(180deg);
  }
  .eye{
    left: 18px;
    right: unset !important;
  }
  .modal-header .btn-close{
    margin-left: 0 !important;
  }
  .set-customdrop-inner .dropdown{
    right: unset !important;
    left: 7px !important;
  }
  .deposit .asset .btn-drop p{
    left: unset !important;
    right: 10px !important;
  }
  .navbar .navbar-collapse .walletmain{
    border-right: 1px solid #242424;
    border-left: unset !important;
  }
   .nav-pills .nav-link{
    font-size: 14px !important;
   }
    .upper-headingold .twice-btns .btn-scanned{
      height: 80px !important;
    }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.dyuvyydvvyddcdcd{
  left: 5% !important;
}