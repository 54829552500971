.generalsetting {
    background: var(--card-bg);
    border: 1px solid var(--card-border2);
    border-radius: 8px;
    padding: 20px;

    .upper-heading {
        background: var(--card-bg);
        border: 1px solid var(--card-border2);
        border-radius: 8px;
        padding: 24px 15px;
        margin-bottom: 20px;

        h6 {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
            text-transform: uppercase;
            color: var(--heading-text);
        }
    }

    .bottom-content {
        .inner-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 18px 12px;
            border: 1px solid var(--card-border2);
            border-radius: 8px;
            margin-bottom: 8px;
            transition: 0.3s linear;
            cursor: pointer;

            &:hover {
                background: var(--card-bg);
                border: 1px solid var(--card-border2);
                border-radius: 8px;
            }

            .left-side {
                display: flex;
                align-items: center;
                gap: 15px;

                .textt {
                    h6 {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 110%;
                        color: var(--heading-text);
                        margin-bottom: 5px;
                    }

                    p {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 110%;
                        color: #7D7D7D;
                    }
                }
            }
        }
    }
}

.language-modal {
    background: rgba(62, 62, 62, 0.3);
    backdrop-filter: blur(3px);

    .modal-header {
        padding: 0;
        border: none;
        background: var(--card-bg);
        border: 1px solid var(--card-border2);
        border-radius: 8px;
        padding: 15px;
        margin-bottom: 10px;

        .modal-title {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
            color: var(--heading-text);
        }

        .btn-close {
            background: url("../../../Assets/close-circle.svg");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            border: none;
            box-shadow: none;
            opacity: 1;
        }
    }

    .modal-content {
        background: var(--card-bg);
        border: 1px solid var(--card-border);
        border-radius: 8px;
        padding: 20px;
    }

    .modal-body {
        padding: 0;
    }

    .eye {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .bottom-content {
        .inner-item {
            border: 1px solid var(--card-border);
            border-radius: 8px;
            margin-bottom: 8px;
            transition: 0.3s linear;
            padding: 15px 12px;

            &:hover {
           
                    background: var(--dropdown-menu-hover);
            
                border: 1px solid var(--card-border2);
                border-radius: 8px;
            }
        }

        .text-para {
            background: var(--warning-card);
            border: 1px solid var(--warning-card-border);
            border-radius: 8px;
            padding: 15px;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 130%;
            color: #ffa800;
            margin-bottom: 30px;
        }
    }

    .twice-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;

        button {
            width: 48%;
            padding: 15px 40px;
            border: none;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;
            text-transform: uppercase;
        }

        .btn-cancel {
            background: var(--button-bg);
            border-radius: 4px;
            color: var(--heading-text);
        }

        .btn-confirm {
            background: #8f1bf5;
            box-shadow: 0px 20px 30px -10px rgba(99, 98, 98, 0.15);
            border-radius: 4px;
            color: #ffffff;
        }
    }

    .bottom-contentt {
        .para {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 110%;
            color: var(--text-color);
            margin-bottom: 20px;
        }

        .phrase-content {
            background: var(--card-bg);
            border: 1px solid var(--card-border2);
            border-radius: 8px;
            padding: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            flex-wrap: wrap;

            .single-phrase {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                flex: 5 0 135px;
                background: var(--card-bg);
                border: 1px solid var(--card-border2);
                border-radius: 5px;
                padding: 10px 15px;

                span {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 14px;
                    text-align: center;
                    letter-spacing: 0.05em;
                    color: #ffffff;
                    background: #8f1bf5;
                }

                p {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: 0.05em;
                    color: var(--heading-text);
                }
            }
        }

        .bottom-para {
            background: #fcf9f2;
            border: 1px solid #fde9c2;
            border-radius: 8px;
            padding: 15px;
            margin: 20px 0;

            p {
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 130%;
                color: #ffa800;
            }
        }
    }

    .custom-radio {
        [type="radio"]:checked,
        [type="radio"]:not(:checked) {
            position: absolute;
            left: -9999px;
        }

        [type="radio"]:checked + label,
        [type="radio"]:not(:checked) + label {
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            line-height: 20px;
            display: inline-block;
            color: var(--text-color);
        }

        [type="radio"]:checked + label:before,
        [type="radio"]:not(:checked) + label:before {
            content: "";
            position: absolute;
            left: 0px;
            top: 0;
            width: 18px;
            height: 18px;
            // border: 1.5px solid #8f1bf5;
            border: 2px solid var(   --radio-check-border);
         

            
            // border: 1.5px solid #8F1BF5;

            border-radius: 100%;
            background: var(--radio-check-language-bg);
        }

        [type="radio"]:checked + label:after,
        [type="radio"]:not(:checked) + label:after {
            content: "";
            width: 12px;
            height: 12px;
            background: #8f1bf5;
            position: absolute;
            top: 3px;
            left: 2.5px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }

        [type="radio"]:not(:checked) + label:after {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
        }

        [type="radio"]:checked + label::before {
            border: 1.5px solid #8F1BF5;
        }
        [type="radio"]:checked + label:after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
}

.chain-prefer {
    background: var(--card-bg);
    border: 1px solid var(--card-border);
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    padding: 20px;
    .main-heading {
        background: var(--card-bg);
        border: 1px solid var(--card-border2);
        border-radius: 8px;
        padding: 15px;
        margin-bottom: 25px;
        h6 {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
            color: var(--heading-text);
        }
    }
    .bottom-side {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--card-bg);
        border: 1px solid var(--card-border2);
        border-radius: 8px;
        padding: 15px;
        margin-bottom: 25px;
        .inner-item {
            display: flex;
            align-items: center;
            gap: 11px;
            h6 {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 110%;
                color: var(--heading-text);
            }
        }
    }
    .bottom-inner {
        background: var(--card-bg);
        border: 1px solid var(--card-border2);
        border-radius: 8px;
        padding: 17px 18px;
        margin-bottom: 20px;
        h6 {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 117%;
            color: var(--heading-text);
        }
    }
    .rpc-url {
        .upper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            h6 {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 140%;
                color: var(--heading-text);
            }
            .iconss {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }
        .bottom-area {
            .inner-text {
                background: var(--card-bg);
                border: 1px solid var(--card-border2);
                border-radius: 8px;
                padding: 17px 18px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                p {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 117%;
                    color: var(--heading-text);
                }
            }
        }
    }
    .twice-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            width: 48%;
            padding: 15px 40px;
            border: none;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;
            text-transform: uppercase;
        }

        .btn-cancel {
            background: var(--button-bg);
            border-radius: 4px;
            color: var(--heading-text);
        }

        .btn-confirm {
            background: #8f1bf5;
            box-shadow: 0px 20px 30px -10px rgba(99, 98, 98, 0.15);
            border-radius: 4px;
            color: #ffffff;
        }
    }
}

@media (max-width: 600px) {
    .language-modal .twice-btn button {
   
        padding: 15px 24px;
  
        font-size: 14px;
     
    }
    .language-modal .bottom-contentt .phrase-content .single-phrase {
  
        flex: 5 0 106px;
   
    }
    .chain-prefer .bottom-side .inner-item h6 {
        font-size: 14px;
    }
    .chain-prefer .bottom-side .inner-item {
        gap: 5px;
    }
    .chain-prefer .bottom-side .inner-item img {
        width: 22px;
        height: 22px;
    }
    .chain-prefer .rpc-url .bottom-area .inner-text p {
        max-width: 226px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
