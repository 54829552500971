.earnrewards {
    padding: 0 15px;

    .earnheadings {
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .redbtnreset {
            display: flex;
            width: 162px;
            padding: 10px 15px;
            justify-content: center;
            align-items: center;
            background: rgba(255, 13, 13, 0.10);
            color: #FF0D0D;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            border: none;
            outline: none;
            white-space: nowrap;
        }

        .newheadings {

            .upperdiv {
                color: var(--card-border);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                margin-top: 12px;
                display: flex;
                align-items: center;
                gap: 10px;

                .adadasdasdasdasdasdsad {
                    color: var(--card-border);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%;
                }
            }

            .joinpra {
                color: var(--card-border);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                margin-top: 12px;

                .boldjoin {
                    font-weight: 700;
                }
            }
        }

        .earnhead {
            color: var(--card-border);
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            margin-bottom: 15px;
        }

        .earnpara {
            color: var(--card-border);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            max-width: 618px;
            width: 100%;
        }
    }

    .heightset {
        height: 328px !important;
        padding: 20px 30px 30px 0px !important;

        .blockimages {
            height: 47px !important;
        }
    }

    .newboxes {
        display: flex;
        align-items: stretch;
        gap: 20px;
        margin-bottom: 20px;

        .invitenewbox {
            border: 1px solid var(--card-border);
            background: transparent;
            padding: 20px 20px;
            max-width: 320px;
            width: 100%;
            // height: 277px;
            flex: 1;

            .goldmain {
                display: flex;
                padding: 8px 4px;
                align-items: center;
                background: rgba(255, 168, 0, 0.10);
                margin-top: 32px;

                .goldpara {
                    color: #FFA800;
                    text-align: center;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                }
            }

            .earnhead {
                color: var(--card-border);
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                margin-bottom: 10px;
            }

            .paraearn {
                color: var(--card-border);
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 14px;

                .greenpara {
                    color: #00B812;
                    font-weight: 700;
                }
            }

            .earnblackbox {
                background: var(--newradialclr);
                padding: 7px 10px;
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 14px;
                justify-content: center;

                .referralearn {
                    color: var(--card-border);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%;
                }
            }

            .lowerearnpara {
                color: var(--card-border);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                text-align: center;

                .boldtext {
                    font-weight: 700;
                }
            }

            .invitehead {
                color: var(--card-border);
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                margin-bottom: 13px;
            }

            .invitepara {
                color: var(--card-border);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;

                /* 24px */
                .invitebodlpara {
                    font-weight: 700;
                }
            }

            .testnetpara {
                color: var(--card-border);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                margin-top: 24px;
            }

            .joinpra {
                color: var(--card-border);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                margin-top: 12px;
                text-align: center;

                .boldjoin {
                    font-weight: 700;
                }
            }
        }

        .earnprogressbox {
            border: 1px solid var(--card-border);
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            padding: 0px 30px 78.5px 0px;
            gap: 10px;
            flex: 1;

            .blockimages {
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: -999;
                height: 65px;
            }

            .earnboxinner {
                display: flex;
                // justify-content: flex-start;
                align-items: center;
                width: 50%;
                padding-top: 8px;

                .earnboxtexts {

                    .earnboxhead {
                        color: var(--card-border);
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 140%;
                        white-space: nowrap;
                        margin-bottom: 13px;
                    }

                    .earnboxpara {
                        color: var(--card-border);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 160%;
                        white-space: nowrap;
                    }
                }
            }

            .congratulationbox {
                background: var(--boxbgclrnew);
                display: flex;
                padding: 0px 10px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 50%;
                max-width: 350px;

                .congratulationimg {
                    width: 50px;
                    height: 50px;
                    margin-bottom: 4px;
                }

                .congratulationhead {
                    color: #00B812;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 160%;
                    margin-bottom: 10px;
                }

                .congratulationpara {
                    color: #00B812;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%;
                }
            }
        }

    }

    .stepbox {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 40px;

        .stepmain {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 40px;
            width: 100%;

            .stepleft {
                max-width: 109px;
                width: 20%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 106px;
                flex-direction: column;

                .darkspan {
                    background: var(--newbgclr) !important;
                    color: var(--newtxtclr) !important;
                    box-shadow: none !important;
                    border: 1px solid transparent !important;
                }

                .stepspan {
                    border: 1px solid var(--card-border);
                    background: var(--body-bg);
                    box-shadow: 4px 4px 0px 0px var(--card-border);
                    display: flex;
                    width: 109px;
                    padding: 10px 25px;
                    align-items: center;
                    justify-content: center;
                    color: var(--card-border);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    white-space: nowrap;
                    cursor: default;
                }

                .stepline {
                    width: 122px;
                    height: 2px;
                    background: var(--card-border);
                    transform: rotate(90deg);
                }

                .darkline {
                    background: var(--linecolor) !important;
                }
            }

            .darkbox {
                border: 1px solid #333 !important;
                box-shadow: none !important;
            }

            .stepright {
                border: 1px solid var(--card-border);
                background: var(--body-bg);
                box-shadow: 4px 4px 0px 0px var(--card-border);
                padding: 20px;
                min-height: 214px;
                width: 100%;

                .steprighthead {
                    color: var(--card-border);
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;
                    margin-bottom: 15px;
                }

                .steprightpara {
                    color: var(--card-border);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%;
                    margin-bottom: 30px;
                }

                .lowerinvitefrnds {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    margin-bottom: 30px;

                    .newsocialicons {
                        max-width: 456px;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .scoialiconimg {
                            width: 28px;
                            height: 28px;
                        }
                    }

                    .lowerinviteleft {
                        width: 100%;

                        .lowerinvitefrndspara {
                            color: var(--card-border);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%;
                            margin-bottom: 14px;
                        }

                        .earnblackbox {
                            background: var(--newradialclr);
                            padding: 7px 10px;
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            justify-content: center;

                            .referralearn {
                                color: var(--card-border);
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 160%;
                            }
                        }

                        .progress {
                            background: #001202;
                            border-radius: 0px;
                            width: 100%;
                            height: 14px;
                            margin-bottom: 15px;

                            .progress-bar {
                                background: #00B812;
                            }
                        }

                        .joinnumberpara {
                            color: var(--card-border);
                            text-align: center;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 160%;
                        }
                    }
                }

                .lowerinviteleft {
                    width: 100%;

                    .lowerinvitefrndspara {
                        color: var(--card-border);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                        margin-bottom: 14px;
                    }

                    .progress {
                        background: #001202;
                        border-radius: 0px;
                        width: 100%;
                        height: 14px;
                        margin-bottom: 15px;

                        .progress-bar {
                            background: #00B812;
                        }
                    }

                    .joinnumberpara {
                        color: var(--card-border);
                        text-align: center;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 160%;
                    }
                }


                .completebtn {
                    border: 1px solid #00B812;
                    background: rgba(0, 184, 18, 0.10);
                    display: flex;
                    width: 248px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    color: #00B812;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                    text-transform: uppercase;
                    padding: 11px;
                    cursor: default;

                    .greenimg {
                        path {
                            fill: #00B812;
                        }
                    }
                }

                .inactivebtn {
                    background: var(--newbgclr) !important;
                    color: var(--newtxtclr) !important;
                    box-shadow: none !important;
                    border: 1px solid transparent !important;
                }

                .activebtn {
                    border: 1px solid var(--card-border);
                    background: var(--body-bg);
                    box-shadow: 4px 4px 0px 0px var(--card-border);
                    display: flex;
                    width: 248px;
                    padding: 15px;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                    color: var(--card-border);
                    text-transform: uppercase;
                }
            }
        }
    }
}

.verifymodal {
    .modal-dialog {
        max-width: 345px;

        .modal-content {
            .modal-body {
                .recaptchaframe {
                    div {
                        iframe {
                            display: block !important;
                            z-index: 9 !important;
                        }
                    }
                }

                .codehead {
                    color: var(--card-border);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;
                    padding-top: 15px;
                    padding-bottom: 10px;
                }

                .codepara {
                    color: var(--card-border);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    margin-bottom: 40px;
                }

                .codeinputs {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    gap: 19px;

                    .codeinnerinput {
                        width: 55px;
                        outline: none;
                        border: none;
                        height: 60px;
                        color: var(--navbar-bg);
                        font-size: 26px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 140%;
                        text-align: center;
                        background: var(--newbgclr) !important;

                        &:focus {
                            background: var(--card-border) !important;
                        }
                    }
                }

                .resendcodepara {
                    color: var(--card-border);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    text-align: center;
                    margin-top: 25px;
                }

                .verifyhead {
                    color: var(--card-border);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;
                    margin-bottom: 43px;
                }

                .numberinput {
                    position: relative;

                    .numberdropdown {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;

                        .btn {
                            background: transparent;
                            border: none;
                            margin: 0;
                            outline: none;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            color: var(--card-border);
                            border-right: 1px solid var(--card-border);
                            padding: 16px;
                            border-radius: 0px;
                            color: var(--card-border);
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 117%;
                            width: 120px;
                        }

                        .dropdown-menu {
                            border: 1px solid var(--card-border);
                            background: var(--card-bg);
                            box-shadow: 4px 4px 0px 0px var(--card-border);
                            border-radius: 0px;
                            width: 120px;

                            .dropdownitm {
                                display: flex;
                                padding: 10px 15px;
                                align-items: center;
                                color: var(--card-border);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 110%;
                                gap: 10px;
                                cursor: pointer;
                            }
                        }
                    }

                    .numberinputinner {
                        border: 1px solid var(--card-border);
                        background: var(--card-bg);
                        color: var(--card-border);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 117%;
                        padding: 16px;
                        outline: none;
                        border-radius: 0px;
                        width: 100%;
                        padding-left: 150px;

                        &::placeholder {
                            color: rgba(255, 255, 255, 0.30);
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 117%;
                        }
                    }
                }

                .verifybtns {
                    display: flex;
                    justify-content: space-between;
                    gap: 20px;
                    align-items: flex-start;
                    margin-top: 28px;

                    .darkbtn {
                        display: flex;
                        padding: 15px;
                        background: var(--newbgclr) !important;
                        justify-content: center;
                        align-items: center;
                        color: var(--card-border);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%;
                        text-transform: uppercase;
                        border: none;
                        outline: none;
                        width: 100%;
                    }

                    .whitebtn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: transparent;
                        padding: 15px;
                        border: 1px solid var(--card-border);
                        box-shadow: 4px 4px 0px 0px var(--card-border);
                        color: var(--card-border);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%;
                        text-transform: uppercase;
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width:1000px) {
    .earnrewards .earnprogressbox .earnboxinner {
        gap: 5px;
    }
}



@media (max-width:992px) {
    .earnrewards .earnprogressbox {
        flex-direction: column;

        justify-content: center;
        align-items: center;
        padding: 31px 20px;
        display: block;
        margin-bottom: 0px !important;
    }

    .earnrewards .newboxes .invitenewbox {
        max-width: 100%;
        margin-bottom: 40px;
    }

    .earnrewards .heightset {
        height: auto !important;
        padding: 30px 20px 90px !important;
    }

    .earnrewards .earnprogressbox .earnboxinner {
        padding-top: 0px;
        width: 100%;
        margin: 0 auto;
        justify-content: center;
    }

    .earnrewards .earnprogressbox .congratulationbox {
        margin: 0 auto;
    }

    .earnrewards .newboxes {
        flex-direction: column;
        gap: 40px;
    }

    .earnrewards .stepbox .stepmain .stepright .lowerinvitefrnds {
        flex-direction: column;
    }
}

@media (max-width:600px) {
    .earnrewards .earnheadings {
        flex-direction: column;
        gap: 20px;
    }

    .earnrewards .earnheadings .redbtnreset {
        width: 100%;
    }

    .congratulationmodal .modal-dialog .modal-content .modal-body .walletparaa {
        font-size: 14px !important;
    }

    .congratulationmodal .modal-dialog .modal-content .modal-body .walletaddressparaa {
        font-size: 16px !important;
    }

    .earnrewards {
        padding: 0;
    }

    .earnrewards .stepbox .stepmain {
        flex-direction: column;
        gap: 30px;
    }

    .earnrewards .stepbox .stepmain .stepleft .stepline {
        display: none;
    }

    .earnrewards .stepbox .stepmain .stepright .steprighthead {
        font-size: 18px;
    }

    .earnrewards .stepbox .stepmain .stepright .steprightpara {
        font-size: 14px;
    }

    .earnrewards .stepbox .stepmain .stepright .activebtn {
        width: 100%;
    }

    .earnrewards .earnprogressbox .congratulationbox {
        width: 100%;
    }

    .earnrewards .earnprogressbox .earnboxinner .earnboxtexts .earnboxhead {
        font-size: 20px;
    }

    .earnrewards .earnprogressbox .earnboxinner .earnboxtexts .earnboxpara {
        font-size: 15px;
    }

    .earnrewards .earnheadings .earnpara {
        font-size: 14px;
    }

    .earnrewards .stepbox .stepmain .stepleft {
        width: 100%;
        max-width: 100%;
        align-items: flex-start;
    }

    .earnrewards .stepbox .stepmain .stepright .completebtn {
        width: 100%;
    }

    .earnrewards .heightset {
        height: auto !important;
        padding-bottom: 90px;
    }

    .set-custom-chart-style {
        width: 100% !important;
    }
}

@media (max-width:500px) {
    .earnrewards .earnprogressbox .earnboxinner {
        flex-direction: column;
        margin-bottom: 30px;
    }

    .earnrewards .earnprogressbox {
        height: 392px;
    }
}

.bottom-contract {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 23px;
    background: rgba(255, 255, 255, 0.01);
    padding: 5px 5px 5px 0px;

    p {
        color: var(--heading-text);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
    }

    .multi-text {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        background: rgba(255, 255, 255, 0.03);
        padding: 7px 20px;

        h6 {
            color: var(--heading-text);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
        }
    }

    .dropdown {
        position: relative;

        p {
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 120%;
            color: var(--card-border);

            background-color: var(--card-bg);
            padding: 5px;
            position: absolute;
            top: -14px;
            left: 10px;
            white-space: nowrap;
        }

        .dropdown-toggle {
            background: var(--card-bg);
            border: 1px solid var(--card-border);
            border-radius: 0px;
            padding: 17px 18px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 117%;
            color: var(--heading-text);

            h6 {
                font-weight: 700;
            }

            &::after {
                color: var(--text-color);
            }
        }

        .dropdown-menu {
            background: var(--card-bg);
            border: 1px solid var(--card-border);
            box-shadow: 0px 0px 24px #0000001f;
            border-radius: 8px;
            width: 100%;

            max-height: 190px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 4px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: transparent;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: var(--card-border);
                border-radius: 20px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: var(--card-border);
            }

            a {
                border-bottom: 1px solid var(--card-border);
                padding: 13px 15px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 117%;
                color: var(--heading-text);
                transition: 0.3s linear;
                display: flex;
                cursor: pointer;



                &:hover {
                    background: var(--card-bg3);
                }
            }
        }
    }
}


.giftmodal-scss {
    .bottom-text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 45px;
        margin: 45px 0;

        h6 {
            color: var(--heading-text);
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
        }
    }

    .btn-confirm {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: var(--body-bg);
        border: 1px solid var(--card-border) !important;
        box-shadow: 4px 4px 0px 0px var(--card-border);
        font-style: normal;
        text-transform: uppercase;
        color: var(--card-border);
        width: 100%;
        height: 50px;

        &:hover {
            box-shadow: 2px 2px 0px 0px var(--card-border);
        }
    }
}

.scgvgsvcgyysvytcvty {
    gap: 4px;
}


.scvvstvtscscvtvstc {
    .dropdown-menu {
        border-radius: 0 !important;

        img {
            width: 18px;
            height: 18px;
        }
    }
}


.congratulationmodal {
    .modal-dialog {
        max-width: 436px;

        .modal-content {

            .modal-body {
                .newsocialicons {
                    max-width: 298px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0 auto 30px;

                    .scoialiconimg {
                        width: 28px;
                        height: 28px;
                    }
                }

                .blackmodalbox {
                    background: var(--newradialclr);
                    padding: 7px 10px;
                    display: flex;
                    align-items: center;

                    margin-bottom: 16px;
                    justify-content: space-between;

                    .conratulationparaaa {
                        color: var(--card-border);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                        width: 100%;

                        .adadasdasdasdasdasdsad {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                        }

                        .boldtext {
                            font-weight: 700;
                            color: var(--card-border);
                            position: relative;
                        }
                    }
                }

                .congratulationhead {
                    color: var(--card-border);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;
                    margin-top: 15px;
                    margin-bottom: 10px;
                }

                .conratulationpara {
                    color: var(--congratulation-modal-text);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    margin-bottom: 15px;

                    .boldtext {
                        font-weight: 700;
                        color: var(--card-border);
                        position: relative;
                    }
                }

                .walletparaa {
                    color: var(--card-border);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-align: center;
                }

                .walletaddressparaa {
                    color: var(--card-border);
                    font-size: 19px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    margin-top: 5px;
                    margin-bottom: 15px;
                }

                .lastboldtextt {
                    color: var(--card-border);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;

                    .textundelines {
                        text-decoration: underline !important;
                        color: var(--card-border);
                    }
                }

                .socialsmain {
                    border: 1.5px solid var(--card-border);
                    display: flex;
                    padding: 20px 20px;
                    flex-direction: column;
                    align-items: center;
                    gap: 18px;
                    margin: 30px 0px;

                    .socialpara {
                        color: var(--card-border);
                        text-align: center;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 100%;
                        /* 12px */
                        text-transform: uppercase;
                    }

                    .socialicons {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        max-width: 275px;
                        width: 100%;
                    }
                }

                .btn-common {
                    border: 1px solid var(--card-border);
                    box-shadow: 4px 4px 0px 0px var(--card-border);
                    color: var(--card-border);
                    font-size: 16px;
                    background: transparent;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                    width: 100%;
                    text-transform: uppercase;
                    display: flex;
                    padding: 15px 40px;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}


.modelll {
    text-align: center;
    color: var(--card-border);
}

h6.adadasdasdasdasdasdsad {
    // color: var(--card-border);
    position: relative;
}

.hadhasfdasd {
    padding-right: 10px !important;
}

.sgsgsdggyd {
    box-shadow: none !important;
}

.twitterpara {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-top: 20px;
    color: var(--card-border);
}

.twitterbutton {
    position: relative;

    .asassddsadfs {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        line-height: 18px;
        color: var(--card-border);
        pointer-events: none;
        z-index: 9;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    button {
        span {
            display: none !important;
        }
    }
}


.newboxesfinal {
    .earnprogressbox {
        padding: 30px !important;
    }

    .newfinal-content {
        .para-text {
            color: var(--heading-text);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            margin-bottom: 15px;

            span {
                font-weight: 700;
            }
        }

        .orange-text {
            background: rgba(255, 168, 0, 0.10);
            padding: 20px;
            color: #FFA800;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 160%;
        }
    }

    .invitenewbox {
        padding: 30px !important;
    }

    .right-new-final {
        h6 {
            color: var(--heading-text);
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            margin-bottom: 13px;
        }

        p {
            color: var(--heading-text);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            margin-bottom: 75px;
        }

        a {
            background: var(--newbgclr);
            padding: 15px;
            width: 100%;
            color: var(--newtxtclr);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}


.modal-finalclaim {
    .finalannounce {
        h5 {
            color: #00B812;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            text-transform: uppercase;
            margin-bottom: 16px;
        }

        h6 {
            color: var(--heading-text);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            margin-bottom: 20px;

            span {
                font-weight: 700;
            }
        }

        p {
            background: rgba(255, 168, 0, 0.10);
            padding: 20px;
            color: #FFA800;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 160%;
            margin-bottom: 30px;
        }
    }

    .btn-okay {
        background: var(--card-bg);
        border: 1px solid var(--card-border);
        box-shadow: 4px 4px 0px 0px var(--card-border);
        padding: 19px;
        color: var(--heading-text);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

.after-v5-new-ui{
    .inner-box{
        background: var(--card-bg);
        border: 1px solid var(--card-border);
        box-shadow: 4px 4px 0px 0px var(--card-border);
        padding: 40px 20px;
        margin: 40px 0;
        h6{
            color: var(--heading-text);
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; 
            text-transform: capitalize;
            margin-bottom: 15px;
        }
        p{
            color: var(--heading-text);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; 
            margin-bottom: 30px;
        }
        .twice-btns{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 30px;
            a{
                background: var(--card-bg);
                border: 1px solid var(--card-border);
                box-shadow: 4px 4px 0px 0px var(--card-border);
                padding: 15px 70px;
                color: var(--heading-text);
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                text-transform: uppercase;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
               
                svg{
                    path{
                        fill: var(--card-border);
                    }
                }
            }
        }
    }
}

@media (max-width:600px){
    .earnrewards .newboxes .earnprogressbox{
        padding: 20px !important;
    }
    .newboxesfinal .invitenewbox{
        padding: 20px !important;
    }
    .newboxesfinal .newfinal-content .orange-text{
        font-size: 16px;
    }
    .after-v5-new-ui .inner-box .twice-btns{
        flex-wrap: wrap;
       
    }
    .after-v5-new-ui .inner-box .twice-btns a{
        width: 100%;
        padding: 15px;
    }
}

