.tpldashboard {
    min-height: 100vh;
    padding-top: 20px;
    padding-bottom: 50px;

    .tpldashboard-nav {
        background: var(--card-bg);
        border: 1px solid var(--card-border);
        box-shadow: 4px 4px 0px 0px var(--card-border);
        border-radius: 0px;
    }

    .nav-pills .newclaimlink {
        padding: 12px 15px !important;
        white-space: nowrap;
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        line-height: 19px;
        color: var(--heading-text);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }

    .nav-pills .nav-link {
        padding: 12px 45px;
        background-color: transparent;
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        line-height: 19px;
        word-break: break-all;
        white-space: wrap;
        color: #828284;
        // border: 2px solid transparent;
        border-radius: 0 !important;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        background: var(--card-bg3);
        border-left: 2px solid var(--card-border);
        border-radius: 0 !important;
        color: var(--card-border);
        font-weight: 700;
    }

    .accordion {
        .accordion-item {
            background-color: transparent;
            border-radius: 0;
            color: unset !important;
            border: none;
        }

        .accordion-button {
            &:focus {
                box-shadow: none;
            }

            &::after {
                background: url("../../Assets/arrow-down.svg");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }

            border-radius: 0;
            background-color: transparent;
            padding: 15px;
            display: flex;
            gap: 10px;
            align-items: center;
            white-space: nowrap;
            font-weight: 400;
            font-style: normal;
            font-size: 16px;
            line-height: 19px;
            color: var(--heading-text);
        }

        .accordion-button:not(.collapsed) {
            border: none;
            box-shadow: none;
            border-radius: 0;
        }

        .accordion-body {
            padding: 0;
        }
    }
}

@media (max-width: 1080px) {
    .tpldashboard {
        padding-bottom: 70px;
    }

    .tpldashboard .upper-profile {
        display: none;
    }

    .tpldashboard .tpldashboard-nav {
        height: 100%;
        flex-direction: column !important;
        gap: 15px;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .tpldashboard .nav-pills .nav-link.active,
    .tpldashboard .nav-pills .show>.nav-link {
        // background-color: transparent;
        // border: unset !important;
        white-space: nowrap;
    }

    .tpldashboard .nav-pills .nav-link {
        // background-color: transparent;
        // border: unset !important;
        // white-space: nowrap;
        padding-left: 20;
    }

    .tpldashboard .profile-tabcontent {
        border: unset;
    }

    .tpldashboard .profile-tabcontent .main-heading {
        border: unset;
    }

    .tpldashboard .profile-tabcontent .main-heading {
        padding-left: 0;
    }

    .btn-blue {
        border-radius: 50%;
        padding: 10px !important;

        span {
            display: none;
        }

        img {
            margin: 0 !important;
        }
    }

    .yvdycvyvdyuvc {
        margin-bottom: 30px;
    }

    .tpldashboard .accordion .accordion-button:not(.collapsed) {
        background-color: transparent;
        border: none;
    }

    .tpldashboard .accordion .accordion-button {
        border: none;
        background-color: transparent;
    }

    .tpldashboard .accordion .accordion-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .forsetting {
            width: 100%;
            // padding-left: 47px;
        }
    }
}

.logout-text {
    border: none !important;
    background-color: transparent !important;
    border-left: none !important;
}

.iconns path {
    fill: var(--svg-color);
}

.light-theme .tpldashboard .accordion-button::after {
    background: url("../../Assets/arrow-down.svg") !important;
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}

.dark-theme .tpldashboard .accordion-button::after {
    background: url("../../Assets/arrow-down-white.svg") !important;
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}

@media (max-width:1080px) {
    .bottom-contract {
        flex-direction: column;
        gap: 15px;
    }

    .bottom-contract .multi-text {
        flex-wrap: wrap;
        gap: 15px;
        justify-content: center;

        h6 {
            flex: 0 0 100%;
        }
    }

    .bottom-contract .dropdown .dropdown-toggle {
        width: 100%;
    }

    .bottom-contract .dropdown {
        width: 100%;
    }

    .bottom-contract .dropdown .dropdown-toggle {
        justify-content: center !important;
    }
}

.forneedhelp {
    margin-top: 20px;

    .accordion-item {
        background: var(--card-bg) !important;
        border: 1px solid var(--card-border) !important;
        box-shadow: 4px 4px 0px 0px var(--card-border) !important;
        border-radius: 0px !important;
    }

    .accordion-body {
        a {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 14px;
            color: var(--card-border);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            text-decoration: underline !important;
            padding: 12px 45px;
            border-bottom: 1px solid #242424;
            background: transparent;
        }
    }
}