.main-navbar {
    background: var(--navbar-bg);
    border-bottom: 1px solid #242424;
    position: relative; z-index: 999999;

    .navbar {
        padding: 0px;
        margin: 0px;

        .navbar-brand {
            margin: 0px;
            padding: 0px;

            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;

            .testnetspan {
                border: 1px solid #FFA800;
                background: var(--body-bg);
                display: flex;
                padding: 10px 12px;
                align-items: center;
                gap: 6px;
                color: #FFA800;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 110%;

                .testnetcircle {
                    background-color: #FFA800;
                    width: 8px;
                    height: 8px;
                    border-radius: 120px;
                }
            }
        }

        .navbar-collapse {
            justify-content: flex-end;

            .languagedropdown {
                margin-right: 25px;
                position: relative;

                .dropdown-toggle {
                    background: transparent;
                    border: none;
                    margin: 0;
                    padding: 0;
                    outline: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    color: var(--card-border);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 110%;
                }

                .dropdown-menu {
                    border: 1px solid var(--card-border);
                    background: var(--card-bg);
                    box-shadow: 4px 4px 0px 0px var(--card-border);
                    border-radius: 0px;
                    left: unset !important;
                    right: 0 !important;
                    margin-top: 10px;

                    .dropdownitm {
                        display: flex;
                        padding: 10px 15px;
                        align-items: center;
                        color: var(--card-border);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 110%;
                        gap: 10px;
                        cursor: pointer;
                    }
                }
            }

            .themeimg {
                padding: 21px 15px;
                border-left: 1px solid #242424;
                border-right: 1px solid #242424;
            }

            .walletmain {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                padding: 24px;
                border-left: 1px solid #242424;

                .tokenimg {
                    width: 30px;
                    height: 30px;
                    border-radius: 120px;
                    object-fit: cover;
                    object-position: center;

                    .tokeninnerimg {
                        width: 100%;
                        height: 100%;
                        border-radius: 120px;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                .wallettexts {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    gap: 2px;

                    .wallethead {
                        font-weight: 700;
                        font-style: normal;
                        font-size: 16px;
                        line-height: 110%;
                        color: var(--card-border);
                    }

                    .walletpara {
                        font-weight: 400;
                        font-style: normal;
                        font-size: 12px;
                        line-height: 110%;
                        color: #7D7D7D;
                    }
                }
            }
        }
    }
}

@media (max-width:1080px) {
    .main-navbar {
        padding: 20px 0px;
    }

    .navbar-toggler:focus {
        box-shadow: none !important;
    }

    .main-navbar .navbar .navbar-collapse .walletmain {
        border-left: none;
    }

    .main-navbar .navbar .navbar-collapse .themeimg {
        display: none;
    }

}

@media (max-width:991px) {
    .main-navbar .navbar .navbar-collapse .languagedropdown {
        margin-right: 0px;
        display: flex;
        justify-content: center;
    }
}

.hideBTN {
    display: hide;
}

//1080px




.navbar-toggler {
    border: none;
}


.service-modal {
    background: rgba(0, 0, 0, 0.60);
    backdrop-filter: blur(3px);

    .modal-content {
        border-radius: 0px;
        border: 1px solid var(--card-border);
        background: var(--body-bg);
        padding: 20px 20px 30px 20px;
        box-shadow: 4px 4px 0px 0px var(--card-border);
    }

    .modal-body {
        padding: 0;
    }

    .upper-div {
        border-radius: 0px;
        border: 1px solid var(--card-border);
        background: var(--body-bg);
        padding: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;

        h6 {
            color: var(--card-border);
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            /* 25.2px */
        }
    }

    .bottom-div {
        border: 1px solid var(--card-border);
        border-radius: 0px;
        background: var(--body-bg);
        padding: 20px;

        h6 {
            color: var(--card-border);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            /* 22.4px */
            margin-bottom: 10px;
        }

        p {
            color: var(--card-border);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            /* 19.6px */
        }
    }
}


@media (max-width:1024px) {
    .d-noneformobile {
        display: none !important;
    }

    .formobileshow {
        display: block !important;
    }
    .main-navbar .navbar .navbar-collapse .languagedropdown{
        padding-top: 20px;
    }
}