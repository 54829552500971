.history {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;
    background: var(--card-bg);
    border: 1px solid var(--card-border);
    box-shadow: 4px 4px 0px 0px var(--card-border);
    border-radius: 0px;

    .historyheader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 14px 15px;
        background: var(--card-bg);
        border: 1px solid var(--card-border);
        border-radius: 0px;
        width: 100%;

        .historyhead {
            font-weight: 700;
            font-style: normal;
            font-size: 18px;
            line-height: 140%;
            text-transform: uppercase;
            color: var(--card-border);
        }

        .refreshbtn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 12px;
            gap: 8px;
            background: var(--card-bg);
            border: 1px solid var(--card-border);
            border-radius: 0px;

            span {
                font-weight: 700;
                font-style: normal;
                font-size: 14px;
                line-height: 110%;
                color: var(--card-border);
            }
        }
    }

    .historytable {
        background: var(--card-bg);
        border: 1px solid var(--card-border);
        border-radius: 0px;
        width: 100%;

        table {
            width: 100%;

            thead {
                th {
                    padding: 24px;
                    vertical-align: middle;

                    .tblheader {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 8px;

                        .tblhead {
                            font-weight: 700;
                            font-style: normal;
                            font-size: 14px;
                            line-height: 110%;
                            color: #7D7D7D;

                        }

                        .arrows {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            gap: 1px;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        vertical-align: middle;
                        padding: 15px 24px;
                        border-top: 1px solid var(--card-border);

                        .tbltd {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 5px;

                            .bluetext {
                                font-weight: 700;
                                font-style: normal;
                                font-size: 12px;
                                line-height: 110%;
                                color: var(--card-border);
                            }

                            .serial {
                                font-weight: 700;
                                font-style: normal;
                                font-size: 14px;
                                line-height: 110%;
                                color: var(--card-border);
                            }

                            .darktext {
                                font-weight: 700;
                                font-style: normal;
                                font-size: 14px;
                                line-height: 110%;
                                color: var(--card-border);
                            }

                            .statusbtn {
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                padding: 10px 12px;
                                border-radius: 0px;
                                font-weight: 700;
                                font-style: normal;
                                font-size: 12px;
                                line-height: 110%;
                                width: 103px;
                                gap: 5px;

                                .spinner-border {
                                    width: 15px;
                                    height: 15px;
                                    color: conic-gradient(from 180deg at 50% 50%, #FFA800 0deg, rgba(255, 168, 0, 0) 360deg) !important;
                                    border-width: 1px;

                                    .visually-hidden {
                                        color: conic-gradient(from 180deg at 50% 50%, #FFA800 0deg, rgba(255, 168, 0, 0) 360deg) !important;
                                    }
                                }
                            }

                            .yellow {
                                border: 1px solid var(--yellow-border);
                                color: #FFA800;
                            }

                            .green {
                                color: #00B812;
                                border: 1px solid var(--btn-green-border);
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (max-width:1200px) {
    .history .historytable {
        overflow-x: scroll;
        width: 100%;

        table {
            width: 1200px;
        }

        &::-webkit-scrollbar {
            display: none !important;
        }
    }
}

@media (max-width:991px) {
    .history .historyheader .refreshbtn span {
        display: none;
    }

    .history .historyheader .refreshbtn {
        padding: 7px;
    }

    .history .historyheader .historyhead {
        font-size: 16px;
    }

    .history .historytable {
        display: none !important;
    }

    .history .accordionmobile {
        display: block !important;
        border: 1px solid var(--card-border);
        border-radius: 0px;
        width: 100%;

        .acchead {
            padding: 15px 20px;
            font-weight: 700;
            font-style: normal;
            font-size: 12px;
            line-height: 120%;
            color: #7D7D7D;
        }

        .accordion {
            .accordion-item {
                .accordion-header {
                    width: 100%;

                    .accordion-button {
                        font-weight: 700;
                        font-style: normal;
                        font-size: 14px;
                        line-height: 120%;
                        color: var(--card-border);
                        border-top: 1px solid var(--card-border);
                        padding: 15px 20px;
                    }
                }

                .accordion-collapse {
                    width: 100%;

                    .accordion-body {
                        width: 100%;
                        border-top: 1px solid var(--card-border);
                        padding: 5px 20px 5px;

                        .accinner {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 10px 0px;
                            border-bottom: 1px solid var(--card-border);

                            &:last-child {
                                border-bottom: none !important;
                            }

                            .accinnerpara {
                                font-weight: 700;
                                font-style: normal;
                                font-size: 12px;
                                line-height: 120%;
                                color: #7D7D7D;
                            }

                            .accinnerhead {
                                font-weight: 700;
                                font-style: normal;
                                font-size: 12px;
                                line-height: 120%;
                                color: var(--card-border);
                            }

                            .bluetext {
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                gap: 5px;
                                font-weight: 700;
                                font-style: normal;
                                font-size: 12px;
                                line-height: 110%;
                                color: var(--card-border);
                            }

                            .statusbtn {
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                padding: 7px;
                                border-radius: 0px;
                                font-weight: 700;
                                font-style: normal;
                                font-size: 12px;
                                line-height: 110%;
                                width: 93px;
                                gap: 5px;

                                .spinner-border {
                                    width: 15px;
                                    height: 15px;
                                    color: conic-gradient(from 180deg at 50% 50%, #FFA800 0deg, rgba(255, 168, 0, 0) 360deg) !important;
                                    border-width: 1px;

                                    .visually-hidden {
                                        color: conic-gradient(from 180deg at 50% 50%, #FFA800 0deg, rgba(255, 168, 0, 0) 360deg) !important;
                                    }
                                }
                            }

                            .yellow {
                                border: 1px solid #FDE8BF;
                                color: #FFA800;
                            }

                            .green {
                                color: #00B812;
                                border: 1px solid var(--btn-green-border);
                            }
                        }
                    }
                }
            }
        }
    }
}