.unlockwallet {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 124px 0px;
    min-height: 100vh;

    .innerimport {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
        background: var(--card-bg);
        border: 1px solid var(--card-border);
        border-radius: 0px;
        max-width: 538px;
        margin: 0 auto;
        width: 100%;
        box-shadow: 4px 4px 0px 0px var(--card-border);

        .importheader {
            background: var(--card-bg);
            border: 1px solid var(--card-border);
            border-radius: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 80px;
            width: 100%;
            padding: 20px 15px;
            margin-bottom: 30px;

            .importhead {
                font-weight: 700;
                font-style: normal;
                font-size: 20px;
                line-height: 140%;
                text-align: center;
                color: var(--heading-text);
            }
        }

        .importbody {
            width: 100%;
            p{
                margin-bottom: 20px;
                font-size: 14px;
            }
            .importpara {
                font-weight: 700;
                font-style: normal;
                font-size: 14px;
                line-height: 110%;
                color: var(--text-color);
                margin-bottom: 30px;
            }

            .material-textfield {
                margin-bottom: 25px;

                .clip {
                    position: absolute;
                    top: 15px;
                    right: 18px;
                }

                .eye {
                    position: absolute;
                    top: 15px;
                    right: 18px;
                }
            }

            .common-btntwo {
                width: 100%;
                padding: 15px;
                font-weight: 700;
                font-size: 16px;
                line-height: 100%;
            }
        }
    }
    .para{
        color: var(--text-color);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; 
    }
    .forgot-text{
        text-decoration: underline;
    }
}

@media (max-width:1080px){
    .unlockwallet{
        display: block !important;
    }
    .unlockwallet .innerimport .importheader{
        padding: 20px 0px;
        gap: 20px;
    }
    .unlockwallet .innerimport .importheader .importhead{
        text-align: center;
        font-size: 16px;
    }
    .unlockwallet {
        padding: 60px 0px;
    }
}