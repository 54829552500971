.private-assets {
    background: var(--card-bg);
    border: 1px solid var(--card-border);
    border-radius: 8px;
    padding: 20px;

    .upper-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--card-bg);
        border: 1px solid var(--card-border2);
        border-radius: 8px;
        padding: 14px 15px;
        position: relative;

        .imgeCopy {
            position: absolute;
            bottom: 25px;
            left: 30px;

            span {
                color: var(--heading-text);
                padding-right: 5px;
            }
        }

        .dropdown {
            max-width: 450px;
            width: 100%;

            .dropdown-toggle {
                background: var(--card-bg);
                border: 1px solid var(--dropdown-border-main);
                border-radius: 8px;
                padding: 10px 15px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 90px;



                h6 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 140%;
                    color: var(--heading-text);
                    margin-bottom: 6px;
                    text-align: start;
                }

                p {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 110%;
                    color: #7d7d7d;
                    text-align: start;
                    display: flex;
                    align-items: center;
                }
            }

            .dropdown-menu {
                background: var(--card-bg);
                border: 1px solid var(--card-border2);
                border-radius: 8px;
                width: 100%;

                .main-head {
                    background: var(--card-bg);
                    border-bottom: 1px solid var(--dropdown-border-main);
                    padding: 15px;

                    h6 {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 140%;
                        text-transform: uppercase;
                        color: var(--heading-text);
                    }
                }

                .bottom-links {
                    a {
                        background: var(--card-bg);
                        border-bottom: 1px solid var(--card-border2);
                        padding: 15px;
                        transition: 0.3s linear;
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        // align-items: center;
                        flex-direction: column;

                        &:last-child {
                            border-bottom: none;
                        }

                        &:hover {
                            background: var(--dropdown-menu-hover);
                        }

                        h6 {
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 140%;
                            color: var(--heading-text);
                            margin-bottom: 5px;
                        }

                        p {
                            font-style: normal;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 110%;
                            color: #7d7d7d;
                        }
                    }
                }
            }
        }

        .twice-btns {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            .btn-scanned {
                background: var(--card-bg);
                border: 1px solid var(--card-border2);
                border-radius: 8px;
                padding: 12px;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 110%;
                color: #7d7d7d;
                display: flex;
                align-items: center;
                white-space: nowrap;
            }

            .btn-more {
                background: var(--card-bg);
                border: 1px solid var(--card-border2);
                border-radius: 8px;
                padding: 12px;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 110%;
                color: #7d7d7d;
                display: flex;
                align-items: center;
            }
        }
    }

    .bottom-table {
        background: var(--card-bg);
        border: 1px solid var(--card-border2);
        border-radius: 8px;
        margin-top: 20px;

        .inner-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 20px;
            border-bottom: 1px solid var(--card-border2);
            transition: 0.3s linear;

            &:hover {
                background: var(--dropdown-menu-hover);
            }

            .left-side {
                display: flex;
                align-items: center;
                gap: 11px;

                img {
                    width: 32px;
                    height: 32px;
                    object-fit: contain;
                    border-radius: 50%;
                }

                h6 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 110%;
                    color: var(--heading-text);
                }
            }

            .right-side {
                display: flex;
                align-items: center;
                gap: 50px;

                .available-btn {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 140%;
                    color: #8f1bf5;
                }

                .pending-btn {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 140%;
                    color: var(--heading-text);
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

.myasset-heading {
    font-style: normal;
    font-weight: 700;

    color: var(--heading-text);
    margin-bottom: 20px;
    text-align: start;
    font-size: 18px;
    line-height: 140%;
    text-transform: uppercase;
    margin-top: 20px;
}

.private-assets1 {
    background: var(--card-bg);
    border: 1px solid var(--card-border);
    box-shadow: 4px 4px 0px 0px var(--card-border);
    border-radius: 0px;
    padding: 20px;

    .upper-headingold {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--card-bg);
        border: 1px solid var(--card-border);
        border-radius: 0px;
        padding: 14px 15px;
        position: relative;

        .imgeCopy {
            position: absolute;
            bottom: 25px;
            left: 30px;

            span {
                color: #7d7d7d;
                padding-right: 5px;
                width: 142px !important;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .dropdown {
            max-width: 253px;
            width: 100%;

            .dropdown-toggle {
                background: var(--card-bg);
                border: 1px solid var(--card-border);
                border-radius: 0px;
                padding: 10px 15px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 90px;



                h6 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 140%;
                    color: var(--heading-text);
                    margin-bottom: 6px;
                    text-align: start;
                }

                p {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 110%;
                    color: #7d7d7d;
                    text-align: start;
                    display: flex;
                    align-items: center;
                }
            }

            .dropdown-menu {
                background: var(--card-bg);
                border: 1px solid var(--card-border);
                border-radius: 0px;
                width: 100%;

                .main-head {
                    background: var(--card-bg);
                    border-bottom: 1px solid var(--card-border);
                    padding: 15px;

                    h6 {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 140%;
                        text-transform: uppercase;
                        color: var(--heading-text);
                    }
                }

                .bottom-links {
                    a {
                        background: var(--card-bg);
                        border-bottom: 1px solid var(--card-border);
                        padding: 15px;
                        transition: 0.3s linear;
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        // align-items: center;
                        flex-direction: column;

                        &:last-child {
                            border-bottom: none;
                        }

                        &:hover {
                            background: var(--dropdown-menu-hover);
                        }

                        h6 {
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 140%;
                            color: var(--heading-text);
                            margin-bottom: 5px;
                        }

                        p {
                            font-style: normal;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 110%;
                            color: #7d7d7d;
                            width: 220px !important;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .twice-btns {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            .btn-scanned {
                background: var(--card-bg);
                border: 1px solid var(--card-border2);
                border-radius: 8px;
                padding: 12px;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 110%;
                color: #7d7d7d;
                display: flex;
                align-items: center;
                white-space: nowrap;
            }

            .btn-more {
                background: var(--card-bg);
                border: 1px solid var(--card-border2);
                border-radius: 8px;
                padding: 12px;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 110%;
                color: #7d7d7d;
                display: flex;
                align-items: center;
            }
        }
    }

    .upper-heading {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 14px 15px;
        background: var(--card-bg);
        border: 1px solid var(--card-border2);
        border-radius: 8px;
        margin-bottom: 20px;
        position: relative;

        .eth-elem {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-left: 30px;
            margin-right: 60px;

            h6 {
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 140%;
                // text-transform: uppercase;
                color: var(--heading-text);
            }
        }

        .twice-text {
            display: flex;
            align-items: center;
            gap: 60px;

            h6 {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 110%;
                color: var(--text-color);

                span {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 110%;
                    color: #8f1bf5;
                    margin-right: 8px;
                }
            }
        }
    }

    .table-responsive {
        background: var(--card-bg);
        border: 1px solid var(--card-border);
        border-radius: 0px;

        th {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 110%;
            color: var(--text-color);
            padding: 23px;
            vertical-align: middle;
            white-space: nowrap;
        }

        td {
            padding: 23px;
            vertical-align: middle;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 110%;
            color: var(--heading-text);
            white-space: nowrap;
            border: none;
            border-top: 1px solid var(--card-border2);

            &:first-child {
                color: #8f1bf5 !important;
            }
        }
    }

    .btn-import {
        background: var(--button-bg2);
        border: 1px solid var(--btn-green-border);
        border-radius: 8px;
        padding: 10px 12px;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 110%;
        color: #00b812;
        display: flex;
        align-items: center;
    }
}

// tpl address and secret modal scss here.............................
.address-secret-modal {
    .modal-content {
        background: var(--body-bg);
        border: 1px solid var(--card-border);
        border-radius: 0px;
        padding: 20px;
        box-shadow: 4px 4px 0px 0px var(--card-border);
    }

    .modal-header {
        padding: 0;
        border: none;
        background: var(--card-bg);
        border: 1px solid var(--card-border);
        border-radius: 0px;
        padding: 15px;

        .modal-title {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
            color: var(--card-border);
        }

        .btn-close {
            background: url("../../../Assets/close-circle.svg");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            border: none;
            box-shadow: none;
            opacity: 1;
        }
    }

    .modal-body {
        padding: 0;

        h6 {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
            color: var(--card-border);
            text-align: center;
            margin-top: 30px;
            margin-bottom: 15px;
        }

        .qr-code {
            display: block;
            width: 100%;
            margin: 0 auto;
            text-align: center;
        }

        .copy-address {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: var(--body-bg);
            border: 1px solid var(--card-border);
            border-radius: 0px;
            padding: 17px 18px;
            margin: 30px 0;

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                color: var(--heading-text);
                word-break: break-all;
                max-width: 392px;
                width: 100%;
            }
        }

        .btn-expert {
            background: #8f1bf5;
            box-shadow: 0px 20px 30px -10px rgba(99, 98, 98, 0.15);
            border-radius: 4px;
            padding: 17px;
            width: 100%;
            border: none;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;
            text-transform: uppercase;
            color: #ffffff;
        }
    }
}

// create account scss here.............................
.createaccount {
    background: var(--card-bg);
    border: 1px solid var(--card-border);
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;
    width: 100%;

    .upper-heading {
        background: var(--card-bg);
        border: 1px solid var(--card-border2);
        border-radius: 8px;
        padding: 15px;
        margin-bottom: 20px;

        h6 {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
            color: var(--heading-text);
        }
    }

    .btn-submit {
        background: #8f1bf5;
        border: none;
        box-shadow: 0px 20px 30px -10px rgba(99, 98, 98, 0.15);
        border-radius: 4px;
        padding: 14px;
        width: 100%;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        text-transform: uppercase;
        color: #ffffff;
        margin-top: 30px;
    }

    .secret-key {
        position: relative;
        margin-bottom: 20px;

        p {
            position: absolute;
            top: -14px;
            left: 10px;
            padding: 5px;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 120%;
            color: var(--text-color);
            background-color: var(--card-bg);
        }

        textarea {
            background: var(--card-bg);
            border: 1px solid var(--card-border2);
            border-radius: 8px;
            padding: 17px 18px;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 117%;
            color: var(--text-color);
            height: 138px;
            width: 100%;

            &::placeholder {
                color: #cecece;
            }

            &:focus-visible {
                outline: none;
            }
        }
    }
}

.maintablehead {
    color: var(--heading-text);
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    line-height: 140%;
    text-transform: uppercase;
    padding: 20px 0px;
}

.historytable {
    background: var(--card-bg);
    border: 1px solid var(--card-border);
    border-radius: 0px;
    width: 100%;

    .maintablee {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid var(--card-border);

        &:last-child {
            border-bottom: none !important;
        }

        &:hover {
            background: var(--hover-tbl);
        }

        .lefttable {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;

            .leftimage {
                width: 34px;
                height: 34px;
                border-radius: 120px;
                object-fit: cover;
                object-position: center;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                .leftinnerimg {
                    width: 100%;
                    height: 100%;
                    border-radius: 120px;
                    object-fit: cover;
                    object-position: center;
                }

                .leftcheckimg {
                    width: 20px;
                    height: 20px;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 120px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    bottom: -3px;
                    right: -5px;

                    .leftcheckinnerimg {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 120px;
                    }
                }
            }

            .lefttext {
                color: var(--heading-text);
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
            }
        }

        .righttext {
            color: var(--heading-text);
            font-weight: 700;
            font-size: 26px;
            font-style: normal;
            line-height: 100%;
        }
    }

    table {
        width: 100%;

        thead {
            th {
                padding: 24px;
                vertical-align: middle;

                .tblheader {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;

                    .tblhead {
                        font-weight: 700;
                        font-style: normal;
                        font-size: 14px;
                        line-height: 110%;
                        color: #7d7d7d;
                    }

                    .arrows {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        gap: 1px;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    vertical-align: middle;
                    padding: 15px 24px;
                    border-top: 1px solid var(--card-border2);

                    .tbltd {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 5px;

                        .bluetext {
                            font-weight: 700;
                            font-style: normal;
                            font-size: 12px;
                            line-height: 110%;
                            color: #8f1bf5;
                        }

                        .serial {
                            font-weight: 700;
                            font-style: normal;
                            font-size: 14px;
                            line-height: 110%;
                            color: #8f1bf5;
                        }

                        .darktext {
                            font-weight: 700;
                            font-style: normal;
                            font-size: 14px;
                            line-height: 110%;
                            color: var(--heading-text);
                        }

                        .statusbtn {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            padding: 10px 12px;
                            border-radius: 8px;
                            font-weight: 700;
                            font-style: normal;
                            font-size: 12px;
                            line-height: 110%;
                            width: 103px;
                            gap: 5px;

                            .spinner-border {
                                width: 15px;
                                height: 15px;
                                color: conic-gradient(from 180deg at 50% 50%,
                                        #ffa800 0deg,
                                        rgba(255, 168, 0, 0) 360deg) !important;
                                border-width: 1px;

                                .visually-hidden {
                                    color: conic-gradient(from 180deg at 50% 50%,
                                            #ffa800 0deg,
                                            rgba(255, 168, 0, 0) 360deg) !important;
                                }
                            }
                        }

                        .yellow {
                            background: var(--yellow-bg);
                            border: 1px solid var(--yellow-border);
                            color: #ffa800;
                        }

                        .green {
                            color: #00b812;
                            background: var(--button-bg2);
                            border: 1px solid var(--btn-green-border);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .historytable {
        overflow-x: scroll;
        width: 100%;

        table {
            width: 1200px;
        }

        &::-webkit-scrollbar {
            display: none !important;
        }
    }
}

@media (max-width: 991px) {
    .historyheader .refreshbtn span {
        display: none;
    }

    .historyheader .refreshbtn {
        padding: 7px;
    }

    .historyheader .historyhead {
        font-size: 16px;
    }

    // .historytable {
    //     display: none !important;
    // }

    // .accordionmobile {
    //     display: block !important;
    //     border: 1px solid var(--dropdown-border-main);
    //     border-radius: 15px;
    //     width: 100%;

    //     .acchead {
    //         padding: 15px 20px;
    //         font-weight: 700;
    //         font-style: normal;
    //         font-size: 12px;
    //         line-height: 120%;
    //         color: #7d7d7d;
    //     }

    //     .accordion {
    //         .accordion-item {
    //             .accordion-header {
    //                 width: 100%;

    //                 .accordion-button {
    //                     font-weight: 700;
    //                     font-style: normal;
    //                     font-size: 14px;
    //                     line-height: 120%;
    //                     color: var(--heading-text);
    //                     border-top: 1px solid var(--dropdown-border-main);
    //                     padding: 15px 20px;
    //                 }
    //             }

    //             .accordion-collapse {
    //                 width: 100%;

    //                 .accordion-body {
    //                     width: 100%;
    //                     border-top: 1px solid var(--dropdown-border-main);
    //                     padding: 5px 20px 5px;

    //                     .accinner {
    //                         display: flex;
    //                         justify-content: space-between;
    //                         align-items: center;
    //                         padding: 10px 0px;
    //                         border-bottom: 1px solid var(--dropdown-border-main);

    //                         &:last-child {
    //                             border-bottom: none !important;
    //                         }

    //                         .accinnerpara {
    //                             font-weight: 700;
    //                             font-style: normal;
    //                             font-size: 12px;
    //                             line-height: 120%;
    //                             color: #7d7d7d;
    //                         }

    //                         .accinnerhead {
    //                             font-weight: 700;
    //                             font-style: normal;
    //                             font-size: 12px;
    //                             line-height: 120%;
    //                             color: var(--heading-text);
    //                         }

    //                         .bluetext {
    //                             display: flex;
    //                             justify-content: flex-end;
    //                             align-items: center;
    //                             gap: 5px;
    //                             font-weight: 700;
    //                             font-style: normal;
    //                             font-size: 12px;
    //                             line-height: 110%;
    //                             color: #8f1bf5;
    //                         }

    //                         .statusbtn {
    //                             display: flex;
    //                             flex-direction: row;
    //                             justify-content: center;
    //                             align-items: center;
    //                             padding: 7px;
    //                             border-radius: 8px;
    //                             font-weight: 700;
    //                             font-style: normal;
    //                             font-size: 12px;
    //                             line-height: 110%;
    //                             width: 93px;
    //                             gap: 5px;

    //                             .spinner-border {
    //                                 width: 15px;
    //                                 height: 15px;
    //                                 color: conic-gradient(from 180deg at 50% 50%,
    //                                         #ffa800 0deg,
    //                                         rgba(255, 168, 0, 0) 360deg) !important;
    //                                 border-width: 1px;

    //                                 .visually-hidden {
    //                                     color: conic-gradient(from 180deg at 50% 50%,
    //                                             #ffa800 0deg,
    //                                             rgba(255, 168, 0, 0) 360deg) !important;
    //                                 }
    //                             }
    //                         }

    //                         .yellow {
    //                             background: #fcf8ef;
    //                             border: 1px solid #fde8bf;
    //                             color: #ffa800;
    //                         }

    //                         .green {
    //                             color: #00b812;
    //                             background: #eff9f0;
    //                             border: 1px solid #a7e5ae;
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
}

@media (max-width: 600px) {
    .private-assets .upper-heading {
        flex-direction: column;
        gap: 20px;
    }

    .historytable .maintablee .lefttable .lefttext{
        font-size: 14px;
    }

    .historytable .maintablee .righttext{
        font-size: 20px;
    }

    .private-assets1 .upper-headingold .imgeCopy{
        bottom: 100px;
    }

    .private-assets1 .upper-headingold .dropdown{
        max-width: 100%;
    }

    .private-assets1 .upper-headingold {
        flex-direction: column;
        gap: 20px;
    }

    .private-assets {
        padding: 20px 15px;
    }

    .private-assets .bottom-table .inner-item .right-side {
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
    }

    .private-assets .upper-heading .dropdown .dropdown-menu .bottom-links a {
        align-items: flex-start;
    }

    .private-assets1 .upper-headingold .dropdown .dropdown-menu .bottom-links a {
        align-items: flex-start;
    }

    .private-assets1 .upper-heading .eth-elem h6 {
        font-size: 16px;
    }

    .private-assets1 .upper-heading .eth-elem img {
        width: 24px;
        height: 24px;
    }

    .private-assets1 .upper-heading .twice-text {
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
    }

    .private-assets1 .upper-heading .twice-text h6 {
        font-size: 14px;
    }

    .private-assets1 .upper-heading .eth-elem {
        margin-right: 6px;
    }

    .private-assets .upper-heading .dropdown .dropdown-menu .bottom-links a p {
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .private-assets1 .upper-headingold .dropdown .dropdown-menu .bottom-links a p {
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .private-assets .upper-heading .imgeCopy {
        p {
            p {
                .gsvsvcvst {
                    width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }

    .private-assets1 .upper-headingold .imgeCopy {
        p {
            p {
                .gsvsvcvst {
                    width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}

.dropdown-toggle::after {
    color: var(--text-color);
}