.disable{
    box-shadow: none!important;
    // border: 0!important;
    cursor: auto!important;

}

.deposit{
    .upper-para{
        p{
            color: #828284;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}