.transaction {
    max-width: 500px;
    width: 100%;
    background: var(--card-bg);
    border: 1px solid var(--card-border);
    box-shadow: 4px 4px 0px 0px var(--card-border);
    border-radius: 0px;
    padding: 20px;

    .nav {
        background: var(--card-bg);
        border: 1px solid var(--card-border2);
        border-radius: 8px;
        justify-content: space-between;
        margin-bottom: 30px;

        .nav-item {
            flex: 1;

            .nav-link {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 110%;
                color: #7d7d7d;
                padding: 20px 10px;
                border: none;
                border-bottom: 2px solid transparent;
                width: 100%;
                background-color: transparent;
            }
        }
    }

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        border-bottom: 2px solid #3BDAEA;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 110%;
        color: #3BDAEA;
        // border-radius: 0px 7px;
    }

    .Transfer {
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
    }
}

.transfer {
    .twice-btn .btn-drop {
        flex: 0 0 100% !important;
    }

    .balance-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;

        p {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 110%;
            color: var(--heading-text);
        }

        h6 {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 110%;
            color: var(--heading-text);
        }
    }
}

// deposit flow scss here...................
.deposit {
    .twice-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        .btn-drop {
            position: relative;
            background: var(--card-bg);
            border: 1px solid var(--card-border);
            border-radius: 0px;
            padding: 17px 18px;
            flex: 0 0 45%;
            display: flex;
            justify-content: space-between;
            align-items: center;



            p {
                display: flex;
                align-items: center;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 120%;
                color: var(--text-color);
                background-color: var(--card-bg);
                padding: 5px;
                position: absolute;
                top: -14px;
                left: 10px;
                white-space: nowrap;
            }
        }
    }

    .asset {
        .btn-drop {
            position: relative;
            background: var(--card-bg);
            border: 1px solid var(--card-border);
            border-radius: 0px;
            padding: 17px 18px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h6 {
                font-weight: 700;
            }

            .selectmain {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;

                .selectleft {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;

                    .selectimgmain {
                        width: 20px;
                        height: 20px;
                        border-radius: 120px;
                        object-fit: cover;
                        object-position: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;

                        .tpmainimg {

                            width: 11.25px;
                            height: 10.909px;
                            border-radius: 120px;
                            object-fit: cover;
                            object-position: center;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            bottom: -2px;
                            right: -3px;

                            .tpinnerimg {
                                width: 100%;
                                height: 100%;
                                border-radius: 120px;
                                object-fit: cover;
                                object-position: center;
                            }
                        }

                        .selectinnerimg {
                            width: 100%;
                            height: 100%;
                            border-radius: 120px;
                            object-fit: cover;
                            object-position: center;
                        }
                    }

                    .selecttext {
                        color: var(--heading-text);
                        font-weight: 700;
                        font-size: 14px;
                        font-style: normal;
                        line-height: 110%;
                    }
                }

                .selectnumber {
                    color: var(--card-border);
                    font-weight: 700;
                    font-size: 14px;
                    font-style: normal;
                    line-height: 110%;
                }
            }

            p {
                display: flex;
                align-items: center;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 120%;
                color: var(--card-border);
                background-color: var(--card-bg);
                padding: 5px;
                position: absolute;
                top: -14px;
                left: 10px;
                white-space: nowrap;
            }
        }
    }

    .tpl-address {
        margin-top: 30px;

        .dropdown {
            position: relative;

            p {
                display: flex;
                align-items: center;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 120%;
                color: var(--card-border);

                background-color: var(--card-bg);
                padding: 5px;
                position: absolute;
                top: -14px;
                left: 10px;
                white-space: nowrap;
            }

            .dropdown-toggle {
                background: var(--card-bg);
                border: 1px solid var(--card-border);
                border-radius: 0px;
                padding: 17px 18px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 117%;
                color: var(--heading-text);

                h6 {
                    font-weight: 700;
                }

                &::after {
                    color: var(--text-color);
                }
            }

            .dropdown-menu {
                background: var(--card-bg);
                border: 1px solid var(--card-border);
                box-shadow: 0px 0px 24px #0000001f;
                border-radius: 8px;
                width: 100%;

                a {
                    border-bottom: 1px solid var(--card-border);
                    padding: 13px 15px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 117%;
                    color: var(--heading-text);
                    transition: 0.3s linear;

                    &:hover {
                        background: var(--card-bg3);
                    }
                }
            }
        }
    }

    .material-textfield {
        margin-top: 30px;

        label {
            display: flex;
            align-items: center;
            font-weight: 700;
        }
    }

    .btn-deposit {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: var(--body-bg);
        border: 1px solid var(--card-border) !important;
        box-shadow: 4px 4px 0px 0px var(--card-border);
        font-style: normal;
        text-transform: uppercase;
        color: var(--card-border);
        border: none;
        padding: 15px;
        width: 100%;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        /* 16px */
        text-transform: uppercase;

        &:hover {
            box-shadow: 2px 2px 0px 0px var(--card-border);
        }

        margin-top: 30px;
    }
}

//selectnetwork-modal modal scss here.............................
.selectnetwork-modal {
    .modal-content {
        background: var(--card-bg);
        border: 1px solid var(--card-border);
        border-radius: 0px;
        padding: 20px;
        box-shadow: 4px 4px 0px 0px var(--card-border);
    }

    .modal-header {
        padding: 0;
        border: none;
        background: var(--card-bg);
        border: 1px solid var(--card-border);
        border-radius: 0px;
        padding: 15px;

        .modal-title {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
            color: var(--heading-text);
        }

        .btn-close {
            background: url("../../../Assets/close-circle.svg");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            border: none;
            box-shadow: none;
            opacity: 1;
        }
    }

    .modal-body {
        padding: 0;

        .select-chain {
            border: 1px solid var(--card-border);
            border-radius: 0px;
            margin-top: 10px;
            max-height: 402px;
            overflow-y: scroll;

            &::-webkit-scrollbar-thumb {
                background: var(--card-border);
                border-radius: 0px;
            }

            &::-webkit-scrollbar {
                background: var(--scroll-bar);
                border-radius: 0px;
                width: 5px;
            }

            .inner-chain {
                border-bottom: 1px solid var(--card-border);
                padding: 15px 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 11px;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 110%;
                color: var(--heading-text);
                transition: 0.3s linear;

                .forflex {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 15px;

                    .modalmainimg {
                        width: 30.001px;
                        height: 29.091px;
                        border-radius: 120px;
                        object-fit: cover;
                        object-position: center;
                        position: relative;

                        .tpmainimg {
                            width: 16.875px;
                            height: 16.364px;
                            border-radius: 120px;
                            object-fit: cover;
                            object-position: center;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            bottom: -3px;
                            right: -4px;

                            .tpinnerimg {
                                width: 100%;
                                height: 100%;
                                border-radius: 120px;
                                object-fit: cover;
                                object-position: center;
                            }
                        }

                        .modalinnerimg {
                            width: 100%;
                            height: 100%;
                            border-radius: 120px;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                }

                &:hover {
                    background: var(--dropdown-menu-hover);
                }
            }
        }
    }

    .option-field {
        position: relative;
        margin-top: 10px;

        input {
            background: var(--card-bg);
            border: 1px solid var(--card-border);
            border-radius: 0px;
            padding: 17px 18px;
            padding-left: 44px;
            width: 100%;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 117%;
            color: var(--Setting-wallet-Passd-input-text);

            &:focus {
                outline: none;
            }
        }

        .search-icon {
            position: absolute;
            top: 17px;
            left: 18px;
        }
    }
}

.summary-modal {
    .modal-content {
        background: var(--card-bg);
        border: 1px solid var(--card-border);
        border-radius: 0px;
        padding: 20px;
        box-shadow: 4px 4px 0px 0px var(--card-border);
    }

    .modal-header {
        padding: 0;
        border: none;
        background: var(--card-bg);
        border: 1px solid var(--card-border);
        border-radius: 0px;
        padding: 15px;
        margin-bottom: 25px;

        .modal-title {
            h6 {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 140%;
                color: var(--card-border);
                margin-bottom: 7px;
            }

            p {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 110%;
                color: #7D7D7D;
            }
        }
    }

    .modal-body {
        padding: 0;

        .bottom-content {
            .inner-text {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 15px;

                p {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 110%;
                    color: #7D7D7D;
                }

                h6 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 110%;
                    color: var(--card-border);
                }
            }
        }

        .last-text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 25px;
            margin-bottom: 25px;
            border-top: 1px solid var(--card-border);

            h6 {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 110%;
                color: #7D7D7D;
            }

            h5 {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 110%;
                color: var(--card-border);
            }
        }

        .twice-btn {
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                width: 48%;
                padding: 15px 40px;
                border: none;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 100%;
                text-transform: uppercase;
            }

            .btn-cancel {
                background: var(--card-bg2);
                border-radius: 0px;
                color: var(--card-border);
            }

            .btn-confirm {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background: var(--body-bg);
                border: 1px solid var(--card-border) !important;
                box-shadow: 4px 4px 0px 0px var(--card-border);
                font-style: normal;
                text-transform: uppercase;
                color: var(--card-border);

                &:hover {
                    box-shadow: 2px 2px 0px 0px var(--card-border);
                }
            }
        }

        .progress-content {
            padding-bottom: 25px;
            border-bottom: 1px solid var(--card-border2);

            h6 {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 110%;
                color: var(--heading-text);
                margin-bottom: 15px;
            }
        }

        .bottom-text {
            padding-top: 25px;

            .inner-side {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 15px;

                p {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 110%;
                    color: var(--heading-text);
                }

                h6 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 110%;
                    color: #3BDAEA;
                }
            }
        }
    }
}

.success-modal {
    .modal-content {
        background: var(--card-bg);
        border: 1px solid var(--card-border);
        border-radius: 0px;
        padding: 25px;
        box-shadow: 4px 4px 0px 0px var(--card-border);
    }

    .modal-body {
        padding: 0px;

        .success-div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            h6 {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 140%;
                color: var(--card-border);
                margin-top: 15px;
                margin-bottom: 7px;
            }

            p {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 110%;
                color: #7D7D7D;
            }
        }

        .twice-btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 30px;

            button {
                width: 48%;
                padding: 15px;
                border: none;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 100%;
                text-transform: uppercase;
            }

            .btn-cancel {
                background: var(--card-bg2);
                border-radius: 0px;
                color: var(--card-border);
            }

            .btn-confirm {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background: var(--body-bg);
                border: 1px solid var(--card-border) !important;
                box-shadow: 4px 4px 0px 0px var(--card-border);
                font-style: normal;
                text-transform: uppercase;
                color: var(--card-border);
                border: none;
                padding: 15px 0px;

                &:hover {
                    box-shadow: 2px 2px 0px 0px var(--card-border);
                }
            }
        }
    }
}

@media (max-width: 1080px) {
    .summary-modal .modal-body .bottom-content .inner-text h6 {
        max-width: 200px;
        word-break: break-all;
        text-align: right;
    }

    .deposit .twice-btn {
        flex-direction: column;
    }

    .deposit .twice-btn .btn-drop {
        flex: 0 0 100%;
        width: 100%;
    }

    .deposit .tpl-address .dropdown .dropdown-menu a {
        max-width: 290px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .deposit .tpl-address .dropdown .dropdown-toggle {
        h6 {
            width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}



.btn-drop h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 117%;
    color: var(--Setting-wallet-Passd-input-text);
}


.balance-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    p {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 110%;
        color: var(--heading-text);
    }

    h6 {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 110%;
        color: var(--heading-text);
    }
}

.successsssss-modal {
    .tpmodalmainimg {
        width: 48.001px;
        height: 46.546px;
        border-radius: 120px;
        object-fit: cover;
        object-position: center;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .tpmodalinnerimg {
            width: 100%;
            height: 100%;
            border-radius: 120px;
            object-fit: cover;
            object-position: center;
        }

        .tpmainedimg {
            object-fit: cover;
            object-position: center;
            width: 27px;
            height: 26.182px;
            border-radius: 120px;
            position: absolute;
            bottom: -5px;
            right: -4px;
            display: flex;
            justify-content: center;
            align-items: center;

            .innermainedimg {
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
                border-radius: 120px;
            }
        }
    }
}


.set-customdrop-inner {

    .dropdown {
        position: absolute;
        top: 6px;
        right: 7px;

        .dropdown-toggle {
            background-color: transparent;
            border: none;
            box-shadow: none;

            &::after {
                display: none;
            }
        }

        .dropdown-menu {
            background: var(--card-bg);
            border: 1px solid var(--card-border);
            box-shadow: 0px 0px 24px #0000001f;
            border-radius: 8px;
            width: 100%;
            left: unset !important;
            right: 0 !important;
            width: 100%;

            a {
                p {
                    color: var(--text-color);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 117%;
                }

                h6 {
                    border-bottom: 1px solid var(--card-border);
                    padding: 13px 15px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 117%;
                    color: var(--heading-text);
                    transition: 0.3s linear;

                    &:hover {
                        background: var(--card-bg3);
                    }
                }
            }
        }
    }
}


.opennew-div {
    background: var(--card-bg);
    border: 1px solid var(--card-border);
    box-shadow: 0px 0px 24px #0000001f;
    border-radius: 8px;
    width: 100%;
    left: unset !important;
    right: 0 !important;
    width: 100%;
    padding: 10px 0;

    a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--card-border);
        padding: 13px 15px;

        p {
            color: var(--text-color);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 117%;
        }

        h6 {


            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 117%;
            color: var(--heading-text);
            transition: 0.3s linear;

            &:hover {
                background: var(--card-bg3);
            }
        }
    }
}

.linksd {
    color: var(--text-color);
}