.main-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 89px;
    padding-bottom: 127px;
    min-height: 100vh;

    .bannerheadings {
        max-width: 850px;
        margin: 0 auto 60px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .bannerhead {
            font-weight: 700;
            font-style: normal;
            font-size: 40px;
            line-height: 110%;
            text-align: center;
            color: var(--card-border);
            margin-bottom: 15px;
            text-transform: capitalize;
        }

        .bannerpara {
            max-width: 567px;
            font-style: normal;
            font-size: 14px;
            line-height: 110%;
            text-align: center;
            color: var(--text-color);
            text-align: center;
            font-weight: 700;
        }
    }

    .bannerwallet {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 20px;

        .mainwallet {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 35px;
            max-width: 426.5px;
            width: 100%;
            background: var(--card-bg);
            border: 1px solid var(--card-border);
            box-shadow: 4px 4px 0px 0px var(--card-border);
            min-height: 312px;

            .mainhead {
                font-weight: 700;
                font-style: normal;
                font-size: 22px;
                line-height: 90%;
                color: var(--heading-text);
                margin-top: 25px;
                margin-bottom: 19px;
            }

            .mainpara {
                font-weight: 700;
                font-style: normal;
                font-size: 14px;
                line-height: 120%;
                color: #7D7D7D;
                margin-bottom: 25px;
            }

            .common-btnone {
                padding: 15px 40px;
                font-size: 16px;
                line-height: 100%;
                width: 100%;
            }

            .secondary-btnone {
                padding: 15px 40px;
                font-size: 16px;
                line-height: 100%;
                width: 100%;
            }
        }
    }
}

@media (max-width:1080px) {
    .main-banner {
        display: block !important;
        padding-top: 120px;
    }
}

@media (max-width:992px) {
    .main-banner .bannerwallet {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

@media (max-width:600px) {
    .main-banner .bannerheadings .bannerhead {
        font-size: 26px;
        line-height: 140%;
    }

    .main-banner .bannerheadings {
        margin: 0 auto 30px;
    }

    .main-banner .bannerwallet .mainwallet {
        padding: 40px 20px;
    }

    .main-banner {
        padding-top: 17px;
        padding-bottom: 53px;
    }
}

.modal-showstart {
    background: #3e3e3e4d;
    backdrop-filter: blur(3px);

    .modal-dialog {
        .modal-content {
            background: var(--body-bg);
            border: 1px solid var(--card-border);
            border-radius: 0px;
            padding: 20px;
            box-shadow: 4px 4px 0px 0px var(--card-border);

            .modal-header {
                background: var(--body-bg);
                border: 1px solid var(--card-border);
                padding: 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                border-radius: 0px !important;

                .modal-title {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 140%;
                    color: var(--card-border);
                    margin-bottom: 7px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                }
            }

            .modal-body {
                padding: 0px;
                margin: 20px 0px 15px;

                .welcomeheader {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 50px;
                    position: relative;

                    .crossimg {
                        position: absolute;
                        top: -20px;
                        right: -5px;
                    }
                }

                .welcomtext {
                    .welcomehead {
                        color: var(--card-border);
                        text-align: center;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 130%;
                        margin-bottom: 25px;
                    }

                    .welcomepara {
                        color: var(--card-border);
                        text-align: center;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 160%;
                        margin-bottom: 50px;
                    }
                }

                .form-check.radiocheck-btnn {
                    margin-bottom: 19px;

                    label.form-check-label.importhead {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 110%;
                        color: var(--heading-text);
                        padding-left: 5px;
                    }

                    .form-check-input:checked {
                        background-color: #7d7d7d !important;
                        border-color: #7d7d7d !important;
                    }

                    .form-check-input:focus {
                        box-shadow: none !important;
                    }

                    .form-check-input {
                        --bs-form-check-bg: var(--navbar-bg);
                        width: 18px;
                        height: 18px;
                        border-radius: 0px !important;
                        vertical-align: top;
                        background-color: var(--bs-form-check-bg);
                        background-image: var(--bs-form-check-bg-image);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        border: var(--bs-border-width) solid var(--radio-check-border);
                        outline: none !important;
                        border-color: #3d3d3d !important;
                    }
                }

                .continuebutton {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    background: var(--body-bg);
                    border: 1px solid var(--card-border) !important;
                    box-shadow: 4px 4px 0px 0px var(--card-border);
                    font-style: normal;
                    text-transform: uppercase;
                    color: var(--card-border);
                    border: none;
                    font-weight: 700;
                    padding: 15px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                    text-transform: uppercase;
                    width: 100%;

                    &:hover {
                        box-shadow: 2px 2px 0px 0px var(--card-border);
                    }
                }

                .disable-btn {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    font-style: normal;
                    text-transform: uppercase;
                    border: none;
                    font-weight: 700;
                    padding: 15px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                    text-transform: uppercase;
                    border: none !important;
                    background: var(--modal-inner-bg);
                    color: #424848;
                    width: 100% !important;
                }

                .mainmodalstartpara {
                    border-radius: 0px;
                    border: 1px solid var(--card-border);
                    padding: 20px;
                    margin-bottom: 15px;

                    .mainmodalinnerpara {
                        color: var(--card-border);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 117%;
                        margin-bottom: 20px;

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        span {
                            color: var(--card-border);
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 117%;
                        }
                    }
                }
            }
        }
    }
}


.welcome-dop {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 120px 0;
    min-height: 100vh;

    .parent-welcome {
        max-width: 662px;
        width: 100%;
        margin: 0 auto;

        .upper-content {
            h4 {
                color: var(--card-border);
                text-align: center;
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 110%;
                text-transform: capitalize;
            }

            p {
                color: var(--card-border);
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                margin: 20px 0;
            }

            .twice-link {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 35px;

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 14px;
                    color: var(--card-border);
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;
                    text-decoration: underline !important;
                    .firstsvg{
                        path{
                            fill: var(--card-border);
                        }
                    }
                }
            }
        }

        .main-card {
            padding: 40px;
            background: var(--body-bg);
            border: 1px solid var(--card-border);
            border-radius: 0px;
            box-shadow: 4px 4px 0px 0px var(--card-border);

            .textdecor {
                color: var(--card-border);
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 117%;
                text-decoration: underline !important;
            }

            .continuebutton {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background: var(--body-bg);
                border: 1px solid var(--card-border) !important;
                box-shadow: 4px 4px 0px 0px var(--card-border);
                font-style: normal;
                text-transform: uppercase;
                color: var(--card-border);
                border: none;
                font-weight: 700;
                padding: 15px;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                text-transform: uppercase;
                width: 100%;
            }
        }
    }
}