.createwallet {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 124px 0px;
    min-height: 100vh;

    .innerimport {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
        background: var(--card-bg);
        border: 1px solid var(--card-border);
        box-shadow: 4px 4px 0px 0px var(--card-border);
        max-width: 538px;
        margin: 0 auto;
        width: 100%;

        .material-textfield {
            input {
                padding-right: 50px;
            }

            .clip {
                position: absolute;
                right: 20px;
                top: 15px;
            }
        }

        .importheader {
            background: var(--card-bg);
            border: 1px solid var(--card-border);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 80px;
            width: 100%;
            padding: 20px 15px;
            margin-bottom: 30px;

            .importhead {
                font-weight: 700;
                font-style: normal;
                font-size: 20px;
                line-height: 140%;
                text-align: center;
                color: var(--heading-text);
            }
        }

        .importbody {
            width: 100%;

            .importpara {
                font-weight: 400;
                font-style: normal;
                font-size: 14px;
                line-height: 110%;
                color: var(--congratulation-modal-text);
                margin-bottom: 30px;
            }

            .material-textfield {
                margin-bottom: 25px;

                .clip {
                    position: absolute;
                    top: 15px;
                    right: 18px;
                }

                .eye {
                    position: absolute;
                    top: 15px;
                    right: 18px;
                }
            }

            .common-btntwo {
                width: 100%;
                padding: 15px;
                font-weight: 700;
                font-size: 16px;
                line-height: 100%;
            }
        }
    }
}

// Secret Recovery Phrase scss here..............................
.secret-recovery {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 124px 0px;
    min-height: 100vh;

    .secret-recover-div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
        background: var(--card-bg);
        border: 1px solid var(--card-border);
        max-width: 538px;
        margin: 0 auto;
        width: 100%;
        box-shadow: 4px 4px 0px 0px var(--card-border);

        .upper-heading {
            background: var(--card-bg);
            border: 1px solid var(--card-border);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 80px;
            width: 100%;
            padding: 20px 15px;
            margin-bottom: 20px;

            h6 {
                font-weight: 700;
                font-style: normal;
                font-size: 20px;
                line-height: 140%;
                text-align: center;
                color: var(--heading-text);
            }
        }

        .bottom-content {
            .para {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 110%;
                color: #7d7d7d;
                margin-bottom: 20px;
            }

            .phrase-content {
                background: var(--card-bg);
                border: 1px solid var(--card-border);
                padding: 15px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 8px;
                flex-wrap: wrap;

                .single-phrase {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    flex: 1 0 142px;
                    background: var(--card-bg);
                    border: 1px solid var(--card-border);
                    padding: 10px 5px;
                    justify-content: flex-start;

                    &.active {
                        background-color: transparent;

                        p {
                            color: #fff !important;
                        }

                    }

                    span {
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        color: var(--card-border);
                        background: transparent;
                    }

                    p {
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        color: var(--card-border);
                    }
                }
            }

            .bottom-para {
                // background: var(--warning-card);
                border: 1px solid var(--warning-card-border);
                padding: 15px;
                margin: 20px 0;

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 130%;
                    color: #ffa800;
                }
            }

            .twice-btn {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;

                button {
                    flex: 0 0 48%;
                    padding: 15px;
                }

                .btn-skip {
                    background: var(--card-bg2);
                    border: none;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 100%;
                    text-transform: uppercase;
                    color: var(--heading-text);
                    padding: 15px;
                }

                .btn-verify {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    background: var(--body-bg);
                    border: 1px solid var(--card-border) !important;
                    box-shadow: 4px 4px 0px 0px var(--card-border);
                    font-style: normal;
                    text-transform: uppercase;
                    color: var(--card-border);
                    border: none;
                    padding: 15px;
                    color: var(--card-border);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                    text-transform: uppercase;

                    &:hover {
                        box-shadow: 2px 2px 0px 0px var(--card-border);
                        transition: 0.7s;
                    }
                }

                .done-btn {
                    flex: 0 0 100% !important;
                    margin-top: 20px;
                }
            }
        }
    }
}
.congrats {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 124px 0px;
    min-height: 100vh;

    .congrats-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
        background: var(--card-bg);
        border: 1px solid var(--card-border);
        max-width: 538px;
        margin: 0 auto;
        width: 100%;
        box-shadow: 4px 4px 0px 0px var(--card-border);

        .upper-heading {
            margin: 15px 0;

            h6 {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 140%;
                color: var(--heading-text);
                margin-bottom: 10px;
            }

            p {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 120%;
                color: var(--congratulation-modal-text);
            }
        }

        .bottom-content {
            margin-bottom: 30px;

            h6 {
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                color: var(--card-border);
                margin-bottom: 10px;
            }

            ul {
                li {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 120%;
                    color: var(--congratulation-modal-text);
                    margin-bottom: 5px;
                    list-style-type: disc;
                    list-style-position: outside;
                    padding-left: 0.5em;
                    margin-left: 20px;
                }
            }
        }

        .twice-btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            gap: 10px;

            button {
                flex: 0 0 100%;
                padding: 15px;
            }

            .btn-verify {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background: var(--body-bg);
                border: 1px solid var(--card-border) !important;
                box-shadow: 4px 4px 0px 0px var(--card-border);
                font-style: normal;
                text-transform: uppercase;
                color: var(--card-border);
                border: none;
                padding: 15px;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                text-transform: uppercase;

                &:hover {
                    box-shadow: 2px 2px 0px 0px var(--card-border);
                    transition: 0.7s;
                }
            }
        }
    }
}

@media (max-width: 1080px) {
    .congrats{
        display: block !important;
    }
    .secret-recovery{
        display: block !important;
    }
    .createwallet{
        display: block !important;
    }
    .createwallet .innerimport .importheader {
        padding: 20px 0px;
        gap: 20px;
    }

    .createwallet .innerimport .importheader .importhead {
        text-align: start;
    }

    .createwallet {
        padding: 85px 0px;

        img.backarrow {
            margin-left: 5px;
        }
    }

    .secret-recovery .secret-recover-div .bottom-content .phrase-content .single-phrase {
        flex: 0 0 31%;
        grid-gap: 10px;
        gap: 6px;
    }

    .secret-recovery .secret-recover-div .bottom-content .twice-btn .btn-skip {
        font-size: 13px;
    }

    .secret-recovery .secret-recover-div .upper-heading {
        gap: 20px;
        padding: 20px 0;
        justify-content: center;
    }

    .secret-recovery .secret-recover-div .upper-heading h6 {
        text-align: start;
    }

    .secret-recovery {
        padding: 60px 0;
    }
}

.important-note {
    background: #3e3e3e4d;
    backdrop-filter: blur(3px);

    .modal-content {
        background: var(--body-bg);
        border: 1px solid var(--card-border);
        border-radius: 0px;
        padding: 20px;
        box-shadow: 4px 4px 0px 0px var(--card-border);

        .modal-header {
            background: var(--body-bg);
            border: 1px solid var(--card-border);
            padding: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            border-radius: 0px !important;

            .modal-title {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 140%;
                color: var(--card-border);
                margin-bottom: 7px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 0px;
            }

            .btn-close {
                background: url("../../../src/Assets/close-circle.svg");
                background-size: cover;
                padding: 0;
                margin: 0;
                background-position: center;
                background-repeat: no-repeat;
                border: none;
                box-shadow: none;
                opacity: 1;
                width: 24px;
                height: 24px;
            }
        }
    }

    .modal-body {
        padding: 0;

        .importantparaimg {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 30px 0px;
            flex-direction: column;
            gap: 20px;

            .claimpara {
                color: var(--card-border);
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;

                span {
                    font-weight: 700;
                }
            }
        }

        .claimmodalpara {
            color: var(--card-border);
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            padding: 45px 0px;
        }

        .continuebutton {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: var(--body-bg);
            border: 1px solid var(--card-border) !important;
            box-shadow: 4px 4px 0px 0px var(--card-border);
            font-style: normal;
            text-transform: uppercase;
            color: var(--card-border);
            border: none;
            font-weight: 700;
            padding: 15px;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
            width: 100%;

            &:hover {
                box-shadow: 2px 2px 0px 0px var(--card-border);
            }
        }
    }

    .inner-set {
        background: var(--card-bg);
        border: 1px solid var(--card-border);
        border-radius: 0px;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        h6 {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
            color: var(--heading-text);
            margin-bottom: 7px;
        }

        p {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 110%;
            color: var(--congratulation-modal-text);
        }
    }

    .address-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: var(--heading-text);
        max-width: 460px;
        width: 100%;
        margin: 25px 0;
        white-space: normal;
        word-break: break-all;
    }

    .twice-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        button {
            // flex: 0 0 48%;
            padding: 15px;
        }

        .btn-skip {
            background: var(--card-bg2);
            border-radius: 4px;
            border: none;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;
            text-transform: uppercase;
            color: var(--heading-text);
        }

        .btn-verify {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: var(--body-bg);
            border: 1px solid var(--card-border) !important;
            box-shadow: 4px 4px 0px 0px var(--card-border);
            font-style: normal;
            text-transform: uppercase;
            color: var(--card-border);
            border: none;
            padding: 10px;

            &:hover {
                box-shadow: 2px 2px 0px 0px var(--card-border);
                transition: 0.7s;
            }
        }

        .done-btn {
            flex: 0 0 100% !important;
            margin-top: 20px;
        }
    }
}
