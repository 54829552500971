.main-footer {
    background: var(--body-bg);
    border-top: 1px solid #242424;
    padding: 18px 0px;
    width: 100%;

    .innerfooter {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .leftfoot {
            .footline {
                font-weight: 400;
                font-style: normal;
                font-size: 12px;
                line-height: 14px;
                color: #828284;
            }
        }

        .rightfoot {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 7px;

            .rightpara {
                font-weight: 700;
                font-style: normal;
                font-size: 12px;
                line-height: 14px;
                text-transform: uppercase;
                color: #9A9A9A;
            }
        }
    }
}

@media (max-width:768px) {
    .main-footer .innerfooter {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }
}

.middle{
    display: flex;
    align-items: center;
    gap: 20px;
    a{
        color: var(--text-color);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}