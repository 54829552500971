.login{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .login-card{
        max-width: 542px;
        width: 100%;
        border: 1px solid var(--card-border);
        background: var(--body-bg);
        box-shadow: 4px 4px 0px 0px var(--card-border);
        padding: 35px 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h6{
            color: var(--card-border);
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%; /* 24.2px */
            margin: 45px 0;
        }
        .forgotpara{
            color: var(--card-border);
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%; /* 24.2px */
            margin: 15px 0;
            text-align: end;
            width: 100%;
        }
        .material-textfield{
            position: relative;
            .password-icon{
                position: absolute;
                right: 20px;
                top: 13px;
            }
        }
    }
}

@media (max-width:1080px){
    .login{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 120px;
    }
    .login .login-card{
        padding: 20px;
    }
    .login .login-card h6{
        font-size: 18px;
        text-align: center;
    }
    .login{
        padding-left: 15px;
        padding-right: 15px;
    }
}

.btn-login {
    border: 1px solid var(--card-border);
    background: var(--body-bg);
    box-shadow: 4px 4px 0px 0px var(--card-border);
    padding: 15px;
    width: 100%;
    color: var(--card-border);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    /* 16px */
    text-transform: uppercase;
  }